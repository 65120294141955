// Bookings.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS
const URI = process.env.REACT_APP_URI

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 30 },
  {
    field: 'quoteRef',
    headerName: 'Quote Ref',
    width: 100,
    editable: true,
  },
  {
    field: 'customerName',
    headerName: 'Customer name',
    width: 150,
    editable: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
    editable: true,
  },
  {
    field: 'collectionPoint',
    headerName: 'Collecting from',
    width: 120,
    editable: true,
  },
  {
    field: 'deliveryPoint',
    headerName: 'Delivery point',
    width: 120,
    editable: true,
  },
  {
    field: 'sellingPrice',
    headerName: 'Sale cost',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'buyingPrice',
    headerName: 'Buy cost',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'profit',
    headerName: 'profit',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'userName',
    headerName: 'User',
    width: 80,
    editable: true,
  },
  {
    field: 'quoteMonth',
    headerName: 'Month',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'quoteYear',
    headerName: 'Year',
    type: 'number',
    width: 70,
    editable: true,
  }
];

function Enquiries() {
  const [quotes, setQuotes] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState({});

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        console.log('Get request sent for quotes');
        const response = await axios.get(`${URI}/quotes/get-quotes`)
        console.log(response);
        setQuotes(response.data.allQuotes)
        setLoading(false)
      } catch (error) {
        setError('Error fetching quotes'); // Set the error message
        setLoading(false); // Set loading to false even on error
     }
    }
    fetchQuotes()
  }, []);

  const navigate = useNavigate();

  const handleRowClick = async (params) => {
    const quoteNumber = params.row.quoteRef;
    
    try {
      const response = await axios.get(`${URI}/quotes/${quoteNumber}`);
      const quoteData = response.data; // Assuming the response contains booking details
      console.log('quoteData', quoteData);
      // You can navigate to the details page and pass the booking data as a route parameter
      navigate(`/quote-details/${quoteNumber}`, { state: { quoteData } });
    } catch (error) {
      console.error('Error fetching booking data', error);
    }
  };
  const handleCheckboxChange = (params) => {
    const rowId = params.quoteRef;
    setSelectedRowIds((prevSelectedRowIds) => ({
      ...prevSelectedRowIds,
      [rowId]: !prevSelectedRowIds[rowId], // Toggle selection status
    }));
  };
  const deleteSelectedQuotes = async () => {
    const selectedQuoteRefs = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);
  
    if (selectedQuoteRefs.length === 0) {
      return; // No bookings selected, do nothing
    }
  
    confirmAlert({
      title: 'Confirm Deletion',
      message: `Are you sure you want to delete quotes ${selectedQuoteRefs.join(', ')}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              for (const quoteNumber of selectedQuoteRefs) {
                await axios.delete(`${URI}/quotes/${quoteNumber}`);
              }
              // Update the UI or state as needed after successful deletion
              // For example, you can refresh the page here
              window.location.reload();
            } catch (error) {
              console.error('Error deleting quotes:', error);
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
            // Do nothing, user canceled
          },
        },
      ],
    });
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

// Convert the quotes array to the format expected by the Datagrid
const rows = quotes.map((quote, index) => {
  // Check if "sellingPrice" exists and is a valid number
  const sellingPrice = quote.sellingPrice && !isNaN(quote.sellingPrice) ? parseFloat(quote.sellingPrice) : 0;

  // Check if "buyingPrice" exists and is a valid number
  const buyingPrice = quote.buyingPrice && !isNaN(quote.buyingPrice) ? parseFloat(quote.buyingPrice) : sellingPrice;

  // Calculate profit
  const profit = sellingPrice - buyingPrice;

  // Use the updatedAt field for sorting
  const quoteDate = new Date(quote.updatedAt);

  return {
    id: index + 1,
    quoteRef: quote.quoteRef,
    customerName: quote.customerId.customerName,
    description: quote.description,
    collectionPoint: quote.collectionPoint,
    deliveryPoint: quote.deliveryPoint,
    sellingPrice: sellingPrice,
    buyingPrice: buyingPrice,
    profit: profit,
    userName: quote.userId.userName,
    quoteMonth: quote.quoteMonth,
    quoteYear: quote.quoteYear,
    quoteDate: quoteDate,
  };
});

// Sort the rows based on the updatedAt field
rows.sort((a, b) => b.quoteDate - a.quoteDate);
 
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onSelectionModelChange={(newSelection) => {
          // No need to set selectedRowIds here
        }}
        selectionModel={Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId])}
        onRowClick={handleRowClick}
        onCellClick={(params) => {
          if (params.field === '__check__') {
            handleCheckboxChange(params.row);
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
      <Link to="/add-quote"> {/* Add the Link component wrapping the button */}
        <button>Create New Quote</button>
      </Link>
        <button onClick={deleteSelectedQuotes}>Delete Quote</button>
    </Box>
  );
}

export default Enquiries
