import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AddressForm from './AddressForm';

const AddressFormPopup = ({ open, onClose }) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <AddressForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AddressFormPopup;
