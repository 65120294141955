import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, FormControl, InputLabel , Typography} from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
const URI = process.env.REACT_APP_URI

const AddressDetails = () => {
  const navigate = useNavigate();
    const location = useLocation();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countryOptions, setCountryOptions] = useState([]); // Ensure you have the country options.
    const isAlphaNumericLowerCase = (str) => {
      return /^[a-z0-9]+$/.test(str);
    };
    const addressData = location.state.addressData;
    const singleAddress = addressData.address
    console.log('addressData2', addressData);
  const addressName = singleAddress.addressName
  const user = location.state.addressData.user

    const [formData, setFormData] = useState({
      addressName: singleAddress.addressName,
      address1: singleAddress.address1,
      address2: singleAddress.address2,
      address3: singleAddress.address3,
      city: singleAddress.city,
      postcode: singleAddress.postcode,
      country: singleAddress.country,
      userName: user.userName

    })
    useEffect(() => {
      console.log('Getting countries');
      axios.get(`${URI}/addresses/countries`)
        .then((response) => {
          const data = response.data.map((country) => country.name.common);
          data.sort();

          // Find the index of "United Kingdom"
          const ukIndex = data.indexOf("United Kingdom");

          // Move "United Kingdom" to the beginning of the array
          if (ukIndex !== -1) {
            data.splice(ukIndex, 1);
            data.unshift("United Kingdom");
          }
          setCountryOptions(data);
          console.log(response);
        })
        .catch((error) => {
          console.error('Failed to fetch data:', error);
        });
    }, [])


    const handleCountryChange = (event) => {
      setSelectedCountry(event.target.value);
      // Update the formData with the selected country
      setFormData((prevData) => ({
        ...prevData,
        country: event.target.value,
      }));
    }
    const handleChange = (name, value, index = 0) => {
      console.log('changing name: ', name);
      if (name === 'addressName') {
        // Check if the entered value is valid
        if (!isAlphaNumericLowerCase(value)) {
          // You can display an error message or prevent the change if needed.
          console.log('Invalid input for addressName. It must be lowercase, contain only letters or numbers, and have no spaces.');
          return;
        }
      }
      setFormData((prevData) => {
        const updatedData = {
          ...prevData,
          [name]: value
        };
        console.log(updatedData);
        return updatedData;
      });
    };
    const handleSubmit = () => {
      console.log('submitted');
      const addressname = addressName
      // Perform your POST request here using formData
      axios.patch(`${URI}/addresses/${addressname}`, formData)
        .then((response) => {
          console.log('PATCH request successful:', response.data);
          // Add any success message or further actions here
          navigate(`/addresses`);
        })
        .catch((error) => {
          console.error('Error while making the PATCH request:', error);
          // Handle the error or show an error message to the user
        });
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="80%"
        mx="auto"
        bgcolor="rgba(240, 240, 240, 0.8)"
        p={4}
      >
        <form>
          <Typography variant="h3">
            Update Address
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 3 }}>
            Please update any details for the address here.
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 3 }}>
            The Address Name can't be edited. If the address name needs amending, please delete the old address and create a new one.
          </Typography>
          <TextField
            name="addressName"
            label="Address Name"
            value={formData.addressName}
            onChange={(event) => handleChange('addressName', event.target.value)}
            required
            sx={{ width: '100%', backgroundColor: 'light-grey', marginTop: 3 }}
            margin="dense"
            InputProps={{ readOnly: true }} // This makes the field non-editable
          />

          <FormControl sx={{ width: '100%', backgroundColor: 'white' }} margin="dense">
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              name="country"
              label="Country"
              select
              value={formData.country}
              onChange={(event) => handleChange('addressName', event.target.value)}
              // onChange={handleCountryChange}
              required
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            >
              {countryOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="address1"
            label="Address Line 1"
            value={formData.address1}
            onChange={(event) => handleChange('address1', event.target.value)}
            required
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <TextField
            name="address2"
            label="Address Line 2"
            value={formData.address2}
            onChange={(event) => handleChange('address2', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <TextField
            name="address3"
            label="Address Line 3"
            value={formData.address3}
            onChange={(event) => handleChange('address3', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <Typography variant="body1" sx={{ marginTop: 3 }}>
            Please use the right town/city for this address. Spelling and accuracy is important in case we need to use the data elsewhere.
          </Typography>
          <TextField
            id="city"
            label="Town/City"
            value={formData.city}
            onChange={(event) => handleChange('city', event.target.value)}
            required
            sx={{ width: '100%', backgroundColor: 'white', marginTop: 3 }}
            margin="dense"
          />
          <Typography variant="body1" sx={{ marginTop: 3 }}>
            For UK postcodes, please use the space eg 'TS2 1RT'.
          </Typography>
          <TextField
            name="postcode"
            label="Postcode/Zipcode"
            value={formData.postcode}
            onChange={(event) => handleChange('postcode', event.target.value)}
            required
            sx={{ width: '100%', backgroundColor: 'white', marginTop: 3 }}
            margin="dense"
          />
          <TextField
            name="userName"
            label="User name"
            value={formData.userName}
            onChange={(event) => handleChange('userName', event.target.value)}
            required
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <Box my={1}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit Address
            </Button>
          </Box>
        </form>
      </Box>
    );
  };


export default AddressDetails
