import React, { useState } from 'react';
//import { useHistory } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, Typography, FormControl, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { NumericFormat } from 'react-number-format';
import { parseISO } from 'date-fns';
import axios from 'axios';
import AxiosRateLimit from 'axios-rate-limit'
import '../styles/style.css'

import AutoCompleteTracking from '../components/AutoCompleteTracking'
const URI = process.env.REACT_APP_URI

const instance = AxiosRateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 1000
})

// Wrap the AutoCompleteTracking component with React.memo
const MemoizedAutoCompleteTracking = React.memo(AutoCompleteTracking);

const AddTracking = () => {
  const [formData, setFormData] = useState({
    jplRef: null,
    customerName: '',
    collected: '',
    collectedDate: null,
    delivered: '',
    deliveredDate: null,
    userName: '',
    updateList: [
    ],
    suppliers: []
  });

  // const [startFromScratch, setStartFromScratch] = useState(false);

  // const handleStartFromScratch = () => {
  //   console.log('formData when starting from scratch', formData);
  //   setFormData((prevData) => {
  //     const updatedData = { ...prevData };
  
  //     // Define your original default values
  //     const originalDefaultValues = {
  //       jplRef: '',
  //       customerName: '',
  //       collected: '',
  //       collectedDate: null,
  //       delivered: '',
  //       deliveredDate: null,
  //       userName: '',
  //       updateList: [],
  //       suppliers: []
  //     };
  
  //     // Function to check if a value is a valid date
  //     const isValidDate = (date) => {
  //       return date instanceof Date && !isNaN(date);
  //     };
  
  //     // Iterate through each field in the formData object
  //     for (const key in updatedData) {
  //       // Check if the field is undefined or null
  //       if (updatedData[key] === undefined || updatedData[key] === null) {
  //         // Handle date fields separately to ensure they are either in correct date format or null
  //         if (key.endsWith('Date')) {
  //           console.log('ends with date', key);
  //           // Check if the field is a valid date or null
  //           if (isValidDate(updatedData[key])) {
  //             // Keep the valid date
  //             continue;
  //           } else {
  //             // Set the date field to null if it's not a valid date
  //             updatedData[key] = originalDefaultValues[key];
  //           }
  //         } else {
  //           // Replace other undefined/null fields with the original default value
  //           updatedData[key] = originalDefaultValues[key];
  //         }
  //       }
  //     }
  //     console.log('updatedData', updatedData);
  //     return updatedData;
  //   });
  
  //   setStartFromScratch(true);
  // };
  
  


  const [updateSections, setUpdateSections] = useState([]);

  const handleAddUpdateSection = () => {
    let todaysDate = new Date(); // This initializes updateDate with the current date and time

    // Now you can use formattedUpdateDate or updateDate as needed in your code

    setFormData((prevFormData) => ({
      ...prevFormData,
      updateList: [
        ...prevFormData.updateList,
        {
          updateTitle: '',
          updateDescription: '',
          public: false,
          dateOfAction: todaysDate,
          updateDate: todaysDate,
          updatedBy: ''
        },
      ],
    }));
  };

  const handleUpdateSectionChange = (index, fieldName, value) => {
    setFormData((prevFormData) => {
      const updatedList = prevFormData.updateList.map((section, i) => {
        if (i === index) {
          return {
            ...section,
            [fieldName]: value,
          };
        }
        return section;
      });
  
      return {
        ...prevFormData,
        updateList: updatedList,
      };
    });
    console.log('formData in handleUpdateSectionChange',formData);
  };
  

  const renderUpdateSections = () => {
    return formData.updateList.map((section, index) => (
      <div key={index} className="update-section">
        <TextField
          label="Update"
          value={section.updateTitle}
          onChange={(e) => handleUpdateSectionChange(index, 'updateTitle', e.target.value)}
          fullWidth
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
        />
        <TextField
          label="Notes"
          value={section.updateDescription}
          onChange={(e) => handleUpdateSectionChange(index, 'updateDescription', e.target.value)}
          fullWidth
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
        />
      <div>
      <FormControl fullWidth>
          <InputLabel id={`public-label-${index}`}>Public</InputLabel>
          <Select
            labelId={`public-label-${index}`}
            value={section.public}
            label="Public"
            onChange={(e) => handleUpdateSectionChange(index, 'public', e.target.value)}
            // Add proper onChange handler to update the 'public' field of the updateSections array
            sx={{ width: '100%', backgroundColor: 'white', marginTop: '18px', marginBottom: '8px' }}
            margin="dense"
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
      </div>
        <DatePicker
          label="Date of Action"
          value={section.dateOfAction}
          onChange={(date) => handleUpdateSectionChange(index, 'dateOfAction', date)}
          fullWidth
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
          format="dd/MM/yyyy"
        />
        <DatePicker
          label="Update Date"
          value={section.updateDate}
          onChange={(date) => handleUpdateSectionChange(index, 'updateDate', date)}
          fullWidth
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
          format="dd/MM/yyyy"
        />
        <TextField
          label="Updated by"
          value={section.updatedBy}
          onChange={(e) => handleUpdateSectionChange(index, 'updatedBy', e.target.value)}
          fullWidth
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
        />
      </div>
    ));
  };


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupClosed, setPopupClosed] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupClosed(true)
    setIsPopupOpen(false);
  };

  const handleChange = (name, value, index = 0) => {
    console.log('changing name: ', name);
    if (formData.jplRef === null && name !== 'jplRef') {
      // If jplRef is not selected, prevent changes to other fields and show a popup
      alert('Please select jplRef first');
      return;
    }
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      console.log(updatedData);
  
      if (name.startsWith('supplierBooking[')) {
      /*  console.log('name starts with supplierBooking');
        // Handle changes in supplierBooking fields
        const supplierBookingIndex = parseInt(name.match(/\[(\d+)\]/)[1]);
        console.log(supplierBookingIndex);
        console.log('updatedData.supplierBooking.length', updatedData.supplierBooking.length);
        if (supplierBookingIndex >= 0 && supplierBookingIndex < updatedData.supplierBooking.length) {
          console.log('the if statement works');
          let supplierBooking = { ...updatedData.supplierBooking[supplierBookingIndex] };
  
          if (name.endsWith('supplierPriceBeforeROE') || name.endsWith('supplierROE')) {
            console.log('if suppliers price or suppliers roe');
            // Calculate supplierBookingPrice when supplierPriceBeforeROE or supplierROE changes
            const nameParts = name.split('.');
            const propertyName = nameParts.pop(); // Get the final property name
            supplierBooking[propertyName] = value;
            const supplierPriceBeforeROE = parseFloat(supplierBooking.supplierPriceBeforeROE);
            const supplierROE = parseFloat(supplierBooking.supplierROE);
            supplierBooking.supplierBookingPrice = parseFloat((supplierPriceBeforeROE / supplierROE).toFixed(2));
          } else {
            console.log('else not if suppliers price or suppliers roe');
            console.log('supplierBooking', supplierBooking);
            console.log('supplierBooking.supplierName');
            console.log('supplierBooking[name]',supplierBooking[name]);
            // Update other supplierBooking fields
            console.log('value', value);
            console.log('name', name);
            const nameParts = name.split('.');
            const propertyName = nameParts.pop(); // Get the final property name

            supplierBooking[propertyName] = value;
          }
  
          updatedData.supplierBooking[supplierBookingIndex] = supplierBooking;
        } */
      } else {
        // Handle changes in other fields
        updatedData[name] = value;
      }
  
      return updatedData;
    });
  };
  const handleNewNameChange = (newValue, fieldName) => {
    // if (fieldName === 'customerName' && formData.jplRef === null) {
    //   // If the fieldName is not 'jplRef' and jplRef is not selected, prevent changes and show a popup
    //   alert('Please select jplRef first');
    //   return;
    // }
    console.log('handleNewNameChange');
    console.log(newValue);
    console.log('fieldName', fieldName);
    // Disable further callbacks until enabled again
    setFormData((prevData) => {
    console.log('prevData', prevData)
      // if (Array.isArray(fieldName)) {
      //   // Updating a nested field within supplierBooking array
      //   const [arrayName, index, nestedField] = fieldName;
       
      //   if (
      //     prevData[arrayName] &&
      //     Array.isArray(prevData[arrayName]) &&
      //     prevData[arrayName][index]
      //     &&
      //     prevData[arrayName][index][nestedField] !== newValue
      //   ) { 
      //     const updatedSupplierBooking = [...prevData[arrayName]];
      //     console.log('updatedSupplierBooking', updatedSupplierBooking);
      //     const selectedSupplierName = newValue; // Assuming the name property holds the selected name
      //     console.log('selectedSupplierName',selectedSupplierName);
      //     updatedSupplierBooking[index] = {
      //       ...updatedSupplierBooking[index],
      //       [nestedField]: selectedSupplierName,
      //     };
      //     console.log('updatedSupplierBooking[index]',updatedSupplierBooking[index]);

      //     return {
      //       ...prevData,
      //       [arrayName]: updatedSupplierBooking,
      //     };
      //   } else {
      //     console.log('error')
      //     console.log(prevData[arrayName]);
      //     console.log(Array.isArray(prevData[arrayName]));
      //     console.log(prevData[arrayName][index]);
      //     console.log(prevData[arrayName][index][nestedField]);
      //   }
      // } else {
      //   // Updating a single property
      //   console.log('single property');
      //   // console.log('prevData[fieldName]', prevData.fieldName);
      //   if (prevData.customerName === newValue) {
      //     console.log('matches customer name');
      //   } else {
      //     prevData.customerName = newValue
      //     console.log(prevData.customerName);
      //     // This bit below:
      //     return {
      //       ...prevData,
      //       customerName: prevData.customerName,
      //     };
      //   }
      //   if (prevData[fieldName] !== newValue) {
      //     return {
      //       ...prevData,
      //       [fieldName]: newValue,
      //     };
      //   }
      // }
      return prevData;
    });
  }
  // Inside your component
const [jplRefSelected, setJplRefSelected] = useState(false);

  const handleBookingRefNameChange = async (newBookingRefValue, fieldName, handleNewNameChange) => {
    console.log('handleBookingRefNameChange');
    console.log(typeof newBookingRefValue);
    const response = await instance.get(`${URI}/booking/get-single-booking/${newBookingRefValue}`);
    const bookingData = response.data; // Assuming the response contains address details
    setFormData((prevData) => {
      if (prevData[fieldName] !== newBookingRefValue) {
        const customerName = bookingData.customerName;
        const userName = bookingData.userName;
        console.log('customer name when handling quote ref change: ', customerName);
        console.log('handleNewNameChange',handleNewNameChange); // Check if it logs the function or 'undefined'
        const suppliers = []
        const updateList = []
        handleNewNameChange(customerName, 'customerName');
        handleNewNameChange(userName, 'userName')

        let collectedDate;
        // Check if singleBooking exists and collectionDate is defined within it
        if (
          bookingData &&
          bookingData.singleBooking &&
          typeof bookingData.singleBooking.collectionDate !== 'undefined'
        ) {
          collectedDate = bookingData.singleBooking.collectionDate;
        } else {
          collectedDate = null;
        }
        
        let deliveredDate;
        // Check if singleBooking exists and collectionDate is defined within it
        if (
          bookingData &&
          bookingData.singleBooking &&
          typeof bookingData.singleBooking.deliveryDate !== 'undefined'
        ) {
          deliveredDate = bookingData.singleBooking.deliveryDate;
        } else {
          deliveredDate = null;
        }
        
       
        return {
          ...prevData,
          [fieldName]: newBookingRefValue, 
          customerName: customerName,
          collected: 0,
          collectedDate: collectedDate,
          delivered: 0,
          deliveredDate: deliveredDate,
          userName: userName,
          updateList: updateList,
          suppliers: suppliers
        };
      }
      return prevData;
    });
    setJplRefSelected(true)
    console.log('formData end of handleBookingRefChange: ',formData);
  };
  
  
  const handleSubmit = () => {
    console.log('submitted');
    console.log(formData);
    const jplref = formData.jplRef;
    
    console.log(formData.collectedDate);
  
    // Check if collectedDate and deliveredDate are not null and are valid Date objects
    if (!formData.collectedDate || !formData.deliveredDate || isNaN(new Date(formData.collectedDate)) || isNaN(new Date(formData.deliveredDate))) {
      window.alert('Please complete the dates');
      return; // Prevent further execution
    } else {
      instance
        .get(`${URI}/tracking/check/${jplref}`)
        .then((response) => {
          console.log('checkRef', response.data);
  
          if (response.data.trackingNotFound === true) {
            // If tracking is not found, proceed to create a new tracking
            instance
              .post(`${URI}/tracking/start-tracking`, formData)
              .then((response) => {
                console.log('POST request successful:', response.data);
                window.alert('Tracking Submitted!');
                window.location.href = '/tracking';
              })
              .catch((error) => {
                console.error('Error while making the POST request:', error);
                // Handle the error or show an error message to the user
              });
          } else {
            // If tracking is found, alert the user
            window.alert('Already tracked');
          }
        })
        .catch((error) => {
          console.error('Error while checking tracking:', error);
          // Handle the error or show an error message to the user
        });
    }
  };
  
  

  return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="80%"
        mx="auto"
        bgcolor="rgba(240, 240, 240, 0.8)"
        p={4}
      >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form  className="fullWidthForm">
          <div className='first-section'>
            <div className="autocomplete-container">
              <AutoCompleteTracking
                  className="my-input"
                  apiUrl={`${URI}/booking/autocomplete?q=`}
                  id="jplRef"
                  name="jplRef"
                  label="JPL Ref"
                  onNewNameChange={(newValue) => {
                    handleBookingRefNameChange(newValue, 'jplRef', handleNewNameChange);
                    setJplRefSelected(false);
                  }}
                />
            </div>
            {/* Frame or overlay */}
            {!jplRefSelected && (
                <div
                  style={{

                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '10vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity or color as needed
                    zIndex: 9999,
                  }}
                >
                  {/* Optionally include a message */}
                  <p style={{ color: 'white', textAlign: 'center', marginTop: '20vh' }}>
                    Please select jplRef to continue.
                  </p>
                </div>
              )}
          {jplRefSelected && (
            <div className='first-section'>
            {/* <AutoCompleteExample /> */}
              <div className="autocomplete-container">
                <AutoCompleteTracking
                  className="my-input"
                  apiUrl={`${URI}/customers/autocomplete?q=`}
                  id="customer"
                  name="customerName"
                  formData={formData}
                  label="Customer"
                  onNewNameChange={(newValue) => handleNewNameChange(newValue, 'customerName')}
                />
              </div>
            <TextField
              name="customerName"
              label="Temporary placeholder for customer name. Use autocomplete if customer needs to change. Do this until issue is fixed."
              value={formData.customerName}
              onChange={(event) => handleChange('customerName', event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <div className="autocomplete-container">
            <DatePicker
              className="my-input"
              name="collectedDate"
              label="Collection Date"
              value={formData.collectedDate || null}
              onChange={(date) => handleChange('collectedDate', date)}
              sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
              margin="dense"
              format="dd/MM/yyyy"
            />
            </div>
            <div className="autocomplete-container">
            <DatePicker
              className="my-input"
              name="deliveredDate"
              label="Delivery Date"
              value={formData.deliveredDate }
              onChange={(date) => handleChange('deliveredDate', date)}
              sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
              margin="dense"
              format="dd/MM/yyyy"
            />
            </div>
            <div className="autocomplete-container">
            <TextField
              name="userName"
              label="User name"
              value={formData.userName}
              onChange={(event) => handleChange('userName', event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            </div>
              {/*} {formData.suppliers.map((booking, index) => (
              //   <div className='supplier-section' key={index}>
              //   <MemoizedAutoCompleteTracking
              //     className="my-input"
              //       apiUrl={`${URI}/suppliers/autocomplete?q=`}
              //     id="supplier"
              //     name={`supplierName-${index}`} // Use a unique identifier for each supplier's name
              //     label="Supplier Name"
              //     value={booking.supplierName}
              //     onNewNameChange={(newValue) => handleNewNameChange(newValue, ['supplierBooking', index, 'supplierName'])}
              //   />
              // </div>
              // ))}
              */} 
                <Box>
                  {/* ... (previous code remains unchanged) */}
                  <Button variant="contained" color="primary" onClick={handleAddUpdateSection}>
                    Add Update
                  </Button>
                  {renderUpdateSections()}
                  {/* ... (rest of your existing code) */}
                </Box>
                <Box my={1}>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Box>
              </div>
            )}
          </div>
        </form>
      </LocalizationProvider>    
    </Box>
  );
};

  
export default AddTracking;

