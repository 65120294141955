import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import AxiosRateLimit from 'axios-rate-limit'
const instance = AxiosRateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 2000
})

export default function AutoCompleteWorkings({
  apiUrl,
  id,
  name,
  label,
  value,
  onNewNameChange,
}) {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(value);
  const [inputValue, setInputValue] = useState(''); // Set inputValue initially to the provided value
  const [initialRender, setInitialRender] = useState(true);

  // Gets the data from the api url. This should be defined in the parent code.
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await instance.get(apiUrl);
        setOptions(response.data);
      } catch (error) {
        console.error(error);
        setOptions([]); // Set options to an empty array on error
      }
    };

    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Notifys the parent component when a value is selected in the Autocomplete component.
  useEffect(() => {
    if (!initialRender && selectedValue !== value) {
      // Notify the parent component about the selected value
      console.log('Selected value');
      onNewNameChange(selectedValue);
    }
  }, [selectedValue, onNewNameChange, value, initialRender]);

  // Update the selectedValue when the value prop changes
  useEffect(() => {
    if (initialRender) {
      console.log('is initial render');
      // If it's the initial render, don't update the state with the value prop.
      setInitialRender(false);
    } else {
      console.log('is not initial render');
      setSelectedValue(value);
      setInputValue(value);
    }
    // Log the value prop for debugging
    console.log('Value prop for id:', id);
    console.log(' is ', value);
  }, [id, value, initialRender]);

  // Add a console.log statement to log the received 'value'
  console.log('Received back value prop for id :', id);
  console.log(' is ', value);
  return (
    <Autocomplete
      className="my-input"
      disablePortal
      id={id} // Use the id prop
      name={name} // Use the name prop
      options={options}
      inputValue={inputValue} // Set inputValue to control the input value
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue); // Update inputValue when input changes
      }}
      value={selectedValue}
      sx={{ width: '100%', backgroundColor: 'white'  }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label} // Use the label prop
        />
      )}
      onChange={(event, newValue) => {
        setSelectedValue(newValue); // Make sure setSelectedValue is being called
        onNewNameChange(newValue); // Notify the parent component
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
  
}
