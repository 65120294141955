import React, { useState } from 'react';
//import { useHistory } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddressFormPopup from '../components/AddressFormPopup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import AxiosRateLimit from 'axios-rate-limit'

import AutoCompleteBookings from '../components/AutoCompleteBookings'
const URI = process.env.REACT_APP_URI

const instance = AxiosRateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 1000
})

// Wrap the AutoCompleteBookings component with React.memo
const MemoizedAutoCompleteBookings = React.memo(AutoCompleteBookings);

const AddBookings = () => {
  const [formData, setFormData] = useState({
    quoteRef: '',
    orderType: 'Select from the below options',
    customerPurchaseOrderNumber: '',
    customerName: '',
    description: '',
    collectionPoint: '',
    deliveryPoint: '',
    collectionDate: null,
    deliveryDate: null,
    sellingPrice: 0,
    sellingPriceCurrency: 'gbp',
    sellingPriceROE: 1,
    sellingPriceDT: 0,
    buyingPriceDT: 0,
    salesVATCode: 1,
    purchaseVATCode: 1,
    collectionRef: '',
    deliveryRef: '',
    userName: '',
    billNumber: '',
    containers: [''],
    vesselName: '',
    eTD: null,
    eTA: null,
    supplierBooking: [
      {
        supplierName: '',
        supplierContactName: '',
        supplierCargoDetails: '',
        supplierPriceBeforeROE: 0,
        supplierBookingCurrency: 'gbp',
        supplierROE: 1,
        supplierBookingPrice: 0,
        supplierCollectionAddressName: '',
        supplierCollectionAddress1: '',
        supplierCollectionAddress2: '',
        supplierCollectionAddress3: '',
        supplierCollectionCity: '',
        supplierCollectionPostcode: '',
        supplierCollectionCountry: '',
        supplierCollectionDate: null,
        supplierCollectionInstructions: '',
        supplierCollectionRef: '',
        supplierDeliveryAddressName: '',
        supplierDeliveryAddress1: '',
        supplierDeliveryAddress2: '',
        supplierDeliveryAddress3: '',
        supplierDeliveryCity: '',
        supplierDeliveryPostcode: '',
        supplierDeliveryCountry: '',
        supplierDeliveryDate: null,
        supplierDeliveryInstructions: '',
        supplierDeliveryRef: '',
      },
    ],
  });

  const orderTypeOptions = [
    'Select from the below options',
    'Roadfreight Full load - Import',
    'Roadfreight Groupage - Import',
    'Roadfreight Abnormal load - Import',
    'Roadfreight Full load - Export',
    'Roadfreight Groupage - Export',
    'Roadfreight Abnormal load - Export',
    'Roadfreight Full load - Domestic',
    'Roadfreight Groupage - Domestic',
    'Roadfreight Abnormal load - Domestic',
    'Seafreight FCL - Import',
    'Seafreight LCL - Import',
    'Seafreight FCL - Export',
    'Seafreight LCL - Export',
    'Seafreight FCL - Cross Trade',
    'Seafreight LCL - Cross Trade',
    'Seafreight Out-of-gauge - Import',
    'Seafreight Out-of-gauge - Export',
    'Seafreight Out-of-gauge - Cross trade',
    'Seafreight - Charter',
    'Airfreight - Import',
    'Airfreight - Export',
    'Courier - Import',
    'Courier - Export',
    'Courier - Domestic',
    'Customs Clearance only - Export entry',
    'Customs Clearance only - Import entry',
    'Storage',
    'Packing',
    'Container Loading',
    'Container unloading',
    'Freight insurance',
    'Crane Hire',
    'Other logistics service'
  ];


  const [startFromScratch, setStartFromScratch] = useState(false);

  const handleStartFromScratch = () => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
  
      // Define your original default values
      const originalDefaultValues = {
        quoteRef: '',
        orderType: 'Select from the below options',
        customerPurchaseOrderNumber: '',
    customerName: '',
    description: '',
    collectionPoint: '',
    deliveryPoint: '',
    collectionDate: null,
    deliveryDate: null,
    sellingPrice: 0,
    sellingPriceCurrency: 'gbp',
    sellingPriceROE: 1,
    sellingPriceDT: 0,
    buyingPriceDT: 0,
    salesVATCode: 1,
    purchaseVATCode: 1,
    collectionRef: '',
    deliveryRef: '',
    userName: '',
    billNumber: '',
    containers: [''],
    vesselName: '',
    eTD: null,
    eTA: null,
    supplierBooking: [
      {
        supplierName: '',
        supplierContactName: '',
        supplierCargoDetails: '',
        supplierPriceBeforeROE: 0,
        supplierBookingCurrency: 'gbp',
        supplierROE: 1,
        supplierBookingPrice: 0,
        supplierCollectionAddressName: '',
        supplierCollectionAddress1: '',
        supplierCollectionAddress2: '',
        supplierCollectionAddress3: '',
        supplierCollectionCity: '',
        supplierCollectionPostcode: '',
        supplierCollectionCountry: '',
        supplierCollectionDate: null,
        supplierCollectionInstructions: '',
        supplierCollectionRef: '',
        supplierDeliveryAddressName: '',
        supplierDeliveryAddress1: '',
        supplierDeliveryAddress2: '',
        supplierDeliveryAddress3: '',
        supplierDeliveryCity: '',
        supplierDeliveryPostcode: '',
        supplierDeliveryCountry: '',
        supplierDeliveryDate: null,
        supplierDeliveryInstructions: '',
        supplierDeliveryRef: '',
      },
    ],
      };
  
      // Iterate through each field in the formData object
      for (const key in updatedData) {
        // Check if the field is undefined or null
        if (updatedData[key] === undefined || updatedData[key] === null) {
          // Replace the undefined/null field with the original default value
          updatedData[key] = originalDefaultValues[key];
        }
      }
  
      return updatedData;
    });
  
    setStartFromScratch(true);
  };
  
  
  


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupClosed, setPopupClosed] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupClosed(true)
    setIsPopupOpen(false);
  };
  const addContainer = () => {

    if (formData.containers.length === 0) {
      // If the initial container is empty, replace it with two containers
      setFormData({ ...formData, containers: ['', ''] });
    } else {
      setFormData({ ...formData, containers: [...formData.containers, ''] });
    }
    console.log('Formdata when adding container: ', formData);
  };
  
  
  
  
  

  const removeContainer = (indexToRemove) => {

    if (formData.containers.length > 0) {
      const updatedContainers = formData.containers.filter((_, index) => index !== indexToRemove);
      setFormData({ ...formData, containers: updatedContainers });
    }
    console.log('Formdata when removing container: ', formData);
  };

  const handleChange = (name, value, index = 0) => {
    console.log('handleChange');
    console.log('name', name);
    console.log('value', value);
    console.log('formData before handleChange', formData);
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      console.log('prevData',prevData);
      console.log('updatedData',updatedData);
      if (name.startsWith('supplierBooking[')) {
        // Handle changes in supplierBooking fields
        const supplierBookingIndex = parseInt(name.match(/\[(\d+)\]/)[1]);
          if (supplierBookingIndex >= 0 && supplierBookingIndex < updatedData.supplierBooking.length) {
          let supplierBooking = { ...updatedData.supplierBooking[supplierBookingIndex] };
  
          if (name.endsWith('supplierPriceBeforeROE') || name.endsWith('supplierROE')) {
            // Calculate supplierBookingPrice when supplierPriceBeforeROE or supplierROE changes
            const nameParts = name.split('.');
            const propertyName = nameParts.pop(); // Get the final property name
            supplierBooking[propertyName] = value;
            const supplierPriceBeforeROE = parseFloat(supplierBooking.supplierPriceBeforeROE);
            const supplierROE = parseFloat(supplierBooking.supplierROE);
            supplierBooking.supplierBookingPrice = parseFloat((supplierPriceBeforeROE / supplierROE).toFixed(2));
          } else {
            const nameParts = name.split('.');
            const propertyName = nameParts.pop(); // Get the final property name

            supplierBooking[propertyName] = value;
          }
  
          updatedData.supplierBooking[supplierBookingIndex] = supplierBooking;
        }
      } else if (name === 'containers') {
        const updatedContainers = [...updatedData.containers];
        updatedContainers[index] = value;
        console.log('value if name is containers', value);
        return { ...updatedData, containers: updatedContainers };
      } else {
        updatedData[name] = value;
      }
  
      return updatedData;
    });
  
    console.log('Formdata when handle change: ', formData);
  };
  const handleNewNameChange = (newValue, fieldName) => {
    console.log('handleNewNameChange');
    // Disable further callbacks until enabled again
    setFormData((prevData) => {
      if (Array.isArray(fieldName)) {
        // Updating a nested field within supplierBooking array
        const [arrayName, index, nestedField] = fieldName;
       
        if (
          prevData[arrayName] &&
          Array.isArray(prevData[arrayName]) &&
          prevData[arrayName][index]
          &&
          prevData[arrayName][index][nestedField] !== newValue
        ) { 
          const updatedSupplierBooking = [...prevData[arrayName]];
          const selectedSupplierName = newValue; // Assuming the name property holds the selected name
          updatedSupplierBooking[index] = {
            ...updatedSupplierBooking[index],
            [nestedField]: selectedSupplierName,
          };
          return {
            ...prevData,
            [arrayName]: updatedSupplierBooking,
          };
        } else {
          console.log('error')
        }
      } else {
        // Updating a single property
        // console.log('prevData[fieldName]', prevData.fieldName);
        if (prevData.customerName === newValue) {
        } else {
          prevData.customerName = newValue
          // This bit below:
          return {
            ...prevData,
            customerName: prevData.customerName,
          };
        }
        if (prevData[fieldName] !== newValue) {
          return {
            ...prevData,
            [fieldName]: newValue,
          };
        }
      }
      return prevData;
    });
  }
  const handleQuoteRefNameChange = async (newQuoteRefValue, fieldName, handleNewNameChange) => {
    console.log('handleQuoteRefNameChange');
    const response = await instance.get(`${URI}/quotes/${newQuoteRefValue}`);
    const quoteData = response.data; // Assuming the response contains address details
  
    setFormData((prevData) => {
      if (prevData[fieldName] !== newQuoteRefValue) {
        const customerName = quoteData.singleQuote.customerId.customerName;

              // Update supplierBooking array data
        const supplierBooking = quoteData.singleQuote.supplierQuotes.map((supplierQuotes) => ({
          supplierName: supplierQuotes.supplierName || '', // Replace with the appropriate field from your response
          supplierContactName: supplierQuotes.supplierContactName || '',
          supplierCargoDetails: supplierQuotes.supplierDescription || '',
          supplierPriceBeforeROE: supplierQuotes.supplierQuotePrice || 0,
          supplierBookingCurrency: supplierQuotes.supplierquoteCurrency || 'gbp',
          supplierROE: supplierQuotes.supplierQuoteROE || 1,
          supplierBookingPrice: supplierQuotes.supplierQuoteGBP || 0,
          supplierCollectionAddressName: supplierQuotes.supplierCollectionAddressName || '',
          supplierCollectionAddress1: supplierQuotes.supplierCollectionAddress1 || '',
          supplierCollectionAddress2: supplierQuotes.supplierCollectionAddress2 || '',
          supplierCollectionAddress3: supplierQuotes.supplierCollectionAddress3 || '',
          supplierCollectionCity: supplierQuotes.supplierCollectionCity || '',
          supplierCollectionPostcode: supplierQuotes.supplierCollectionPostcode || '',
          supplierCollectionCountry: supplierQuotes.supplierCollectionCountry || '',
          supplierCollectionDate: supplierQuotes.supplierCollectionDate || null,
          supplierCollectionInstructions: supplierQuotes.supplierCollectionInstructions || '',
          supplierCollectionRef: supplierQuotes.supplierCollectionRef || '',
          supplierDeliveryAddressName: supplierQuotes.supplierDeliveryAddressName || '',
          supplierDeliveryAddress1: supplierQuotes.supplierDeliveryAddress1 || '',
          supplierDeliveryAddress2: supplierQuotes.supplierDeliveryAddress2 || '',
          supplierDeliveryAddress3: supplierQuotes.supplierDeliveryAddress3 || '',
          supplierDeliveryCity: supplierQuotes.supplierDeliveryCity || '',
          supplierDeliveryPostcode: supplierQuotes.supplierDeliveryPostcode || '',
          supplierDeliveryCountry: supplierQuotes.supplierDeliveryCountry || '',
          supplierDeliveryDate: supplierQuotes.supplierDeliveryDate || null,
          supplierDeliveryInstructions: supplierQuotes.supplierDeliveryInstructions || '',
          supplierDeliveryRef: supplierQuotes.supplierDeliveryRef || '',
        }));
        // Triggering handleChange with the customerName value
        handleNewNameChange(customerName, 'customerName');

        return {
          ...prevData,
          [fieldName]: newQuoteRefValue,
          orderType: quoteData.singleQuote.orderType,
          description: quoteData.singleQuote.description,
          collectionPoint: quoteData.singleQuote.collectionPoint,
          deliveryPoint: quoteData.singleQuote.deliveryPoint,
          sellingPrice: quoteData.sellingPrice,
          sellingPriceCurrency: quoteData.singleQuote.sellingPriceCurrency,
          sellingPriceROE: quoteData.singleQuote.quoteROE,
          sellingPriceGBP: quoteData.singleQuote.sellingPriceGBP,
          buyingPrice: quoteData.singleQuote.buyingPrice,
          profit: quoteData.singleQuote.profit,
          supplierBooking: supplierBooking
        };
      }
      return prevData;
    });
  console.log('Formdata when clicking continue: ', formData);
  };
  
  const handleSupplierCollectionAddressNameChange = async (newSupplierAddressValue, fieldName) => {
    const response = await instance.get(`${URI}/addresses/get-collection-address/${newSupplierAddressValue}`);
    const addressData = response.data; // Assuming the response contains address details
    // Disable further callbacks until enabled again
    setFormData((prevData) => {
      if (Array.isArray(fieldName)) {
        // Updating a nested field within supplierBooking array
        const [arrayName, index, nestedField] = fieldName;
        if (
          prevData[arrayName] &&
          Array.isArray(prevData[arrayName]) &&
          prevData[arrayName][index]
          &&
          prevData[arrayName][index][nestedField] !== newSupplierAddressValue
        ) { 
          const updatedSupplierBooking = [...prevData[arrayName]];
          const selectedSupplierCollectionAddressName = newSupplierAddressValue; // Assuming the name property holds the selected name
          updatedSupplierBooking[index] = {
            ...updatedSupplierBooking[index],
            [nestedField]: selectedSupplierCollectionAddressName,
            supplierCollectionAddress1: addressData.address.address1,
            supplierCollectionAddress2: addressData.address.address2,
            supplierCollectionAddress3: addressData.address.address3,
            supplierCollectionCity: addressData.address.city,
            supplierCollectionPostcode: addressData.address.postcode,
            supplierCollectionCountry: addressData.address.country,
          }
          return {
            ...prevData,
            [arrayName]: updatedSupplierBooking,
          };
        } else {
          console.log('error')
        }
      } else {
        // Updating a single property
        if (prevData[fieldName] !== newSupplierAddressValue) {
          return {
            ...prevData,
            [fieldName]: newSupplierAddressValue,
          };
        }
      }
      return prevData;
    });
  }
  const handleSupplierDeliveryAddressNameChange = async (newSupplierAddressValue, fieldName) => {
    const response = await instance.get(`${URI}/addresses/get-delivery-address/${newSupplierAddressValue}`);
    const addressData = response.data; // Assuming the response contains address details
    // Disable further callbacks until enabled again
    setFormData((prevData) => {
      if (Array.isArray(fieldName)) {
        // Updating a nested field within supplierBooking array
        const [arrayName, index, nestedField] = fieldName;
        if (
          prevData[arrayName] &&
          Array.isArray(prevData[arrayName]) &&
          prevData[arrayName][index]
          &&
          prevData[arrayName][index][nestedField] !== newSupplierAddressValue
        ) { 
          const updatedSupplierBooking = [...prevData[arrayName]];
          const selectedSupplierDeliveryAddressName = newSupplierAddressValue; // Assuming the name property holds the selected name
          updatedSupplierBooking[index] = {
            ...updatedSupplierBooking[index],
            [nestedField]: selectedSupplierDeliveryAddressName,
            supplierDeliveryAddress1: addressData.address.address1,
            supplierDeliveryAddress2: addressData.address.address2,
            supplierDeliveryAddress3: addressData.address.address3,
            supplierDeliveryCity: addressData.address.city,
            supplierDeliveryPostcode: addressData.address.postcode,
            supplierDeliveryCountry: addressData.address.country,
          };

          return {
            ...prevData,
            [arrayName]: updatedSupplierBooking,
          };
        } else {
          console.log('error')
        }
      } else {
        // Updating a single property
        if (prevData[fieldName] !== newSupplierAddressValue) {
          return {
            ...prevData,
            [fieldName]: newSupplierAddressValue,
          };
        }
      }
      return prevData;
    });
  }
  const handleAddSupplierBooking = () => {
    console.log('handleAddSupplierBooking');
    setFormData((prevData) => ({
      ...prevData,
      supplierBooking: [
        ...prevData.supplierBooking,
        {
          supplierName: '',
          supplierContactName: '',
          supplierCargoDetails: '',
          supplierPriceBeforeROE: 0,
          supplierBookingCurrency: 'gbp',
          supplierROE: 1,
          supplierBookingPrice: 0,
          supplierCollectionAddressName: '',
        supplierCollectionAddress1: '',
        supplierCollectionAddress2: '',
        supplierCollectionAddress3: '',
        supplierCollectionCity: '',
        supplierCollectionPostcode: '',
        supplierCollectionCountry: '',
          supplierCollectionDate: null,
          supplierCollectionInstructions: '',
          supplierCollectionRef: '',
          supplierDeliveryAddressName: '',
        supplierDeliveryAddress1: '',
        supplierDeliveryAddress2: '',
        supplierDeliveryAddress3: '',
        supplierDeliveryCity: '',
        supplierDeliveryPostcode: '',
        supplierDeliveryCountry: '',
          supplierDeliveryDate: null,
          supplierDeliveryInstructions: '',
          supplierDeliveryRef: '',
        },
      ],
    }));
  };
  const handleSubmit = () => {
    console.log('submitted');
    
    // Perform a check against formData for undefined or null fields
    const updatedFormData = { ...formData };
    const initialFormData = { /* Your initial form data object here */ };
  
    // Loop through formData and update undefined/null fields with default values
    for (const key in updatedFormData) {
      if (updatedFormData[key] === undefined || updatedFormData[key] === null) {
        updatedFormData[key] = initialFormData[key];
      }
    }
    if (updatedFormData.orderType === 'Select from the below options' || formData.orderType === undefined) {
      window.alert('Select shipment type')
      return
    } 
  
    console.log('formData before sending: ', updatedFormData);
  
    // Perform your POST request here using updatedFormData
    instance
      .post(`${URI}/booking/new-booking`, updatedFormData)
      .then((response) => {
        // Handle successful response
        console.log('POST request successful:', response.data);
        // Display success message or redirect to another page
        window.alert('Booking Submitted!');
        window.location.href = '/bookings';
      })
      .catch((error) => {
        // Handle error
        console.error('Error while making the POST request:', error);
        console.log('This is what the post request was supposed to be: ', updatedFormData);
        // Show error message to the user
      });
  };
  
  
  

  return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="80%"
        mx="auto"
        bgcolor="rgba(240, 240, 240, 0.8)"
        p={4}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form>
          <div className='first-section'>
        <AutoCompleteBookings
          className="my-input"
          apiUrl={`${URI}/quotes/autocomplete?q=`}
          id="quoteRef"
          name="quoteRef"
          label="Quote Ref"
          onNewNameChange={(newValue) =>
            handleQuoteRefNameChange(newValue, 'quoteRef', handleNewNameChange)
          }
        />
            {!startFromScratch && (
              <Box my={1}>
              <Typography variant="body1">
                Enter quote ref if necessary and click continue
              </Typography>
                <Button variant="contained" color="primary" onClick={handleStartFromScratch}>
                  Continue
                </Button>
              </Box>
            )}
            </div>
          {startFromScratch && (
            <>
               <div className='first-section'>
          <div className="autocomplete-container">
        <Select
          className="my-input"
          name="orderType"
          label="Order Type"
          value={formData.orderType}
          onChange={(event) => handleChange('orderType', event.target.value)}
          sx={{ width: '100%', backgroundColor: 'white' }}
          margin="dense"
        >
          {orderTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          </Select>
          </div>
          {/* <AutoCompleteExample /> */}
          <AutoCompleteBookings
            className="my-input"
            apiUrl={`${URI}/customers/autocomplete?q=`}
            id="customer"
            name="customerName"
            formData={formData}
            label="Customer"
            onNewNameChange={(newValue) => handleNewNameChange(newValue, 'customerName')}
            />
      <TextField
        name="customerName"
        label="Temporary placeholder for customer name. Copy to autocomplete for now until issue is fixed."
        value={formData.customerName}
        onChange={(event) => handleChange('customerName', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <TextField
        name="userName"
        label="User"
        value={formData.userName}
        onChange={(event) => handleChange('userName', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <TextField
        name="customerPurchaseOrderNumber"
        label="Customer PO"
        value={formData.customerPurchaseOrderNumber}
        onChange={(event) => handleChange('customerPurchaseOrderNumber', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <TextField
        name="description"
        label="Description"
        value={formData.description}
        onChange={(event) => handleChange('description', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <TextField
        name="collectionPoint"
        label="Collection Point"
        value={formData.collectionPoint}
        onChange={(event) => handleChange('collectionPoint', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <TextField
        name="deliveryPoint"
        label="Delivery Point"
        value={formData.deliveryPoint}
        onChange={(event) => handleChange('deliveryPoint', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
          />
          <div className="autocomplete-container">
      <DatePicker
        className="my-input"
        name="collectionDate"
        label="Collection Date"
        value={formData.collectionDate}
        onChange={(date) => handleChange('collectionDate', date)}
        sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
        margin="dense"
        format="dd/MM/yyyy"
        />
      </div>
      <div className="autocomplete-container">
      <DatePicker
        className="my-input"
        name="deliveryDate"
        label="Delivery Date"
        value={formData.deliveryDate}
        onChange={(date) => handleChange('deliveryDate', date)}
        sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
        margin="dense"
        format="dd/MM/yyyy"
      />
      </div>
      <div className="autocomplete-container">
      <NumericFormat
        className="my-input"
        customInput={TextField}
        thousandSeparator
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        name="sellingPrice"
        label="Sale cost"
        value={formData.sellingPrice}
        onValueChange={({ floatValue }) => handleChange('sellingPrice', floatValue)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
            />
          </div>
          <div className="autocomplete-container">
      <Select
        className="my-input"
        name="sellingPriceCurrency"
        label="Currency"
        value={formData.sellingPriceCurrency}
        onChange={(event) => handleChange('sellingPriceCurrency', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      >
        <MenuItem value="gbp">GBP</MenuItem>
        <MenuItem value="usd">USD</MenuItem>
        <MenuItem value="eur">EUR</MenuItem>
            </Select>
            </div>
      <TextField
        type="number"
        name="sellingPriceROE"
        label="Rate of Exchange"
        value={formData.sellingPriceROE}
        onChange={(event) => handleChange('sellingPriceROE', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <NumericFormat
        className="my-input"
        customInput={TextField}
        thousandSeparator
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        name="sellingPriceDT"
        label="Sale duties and taxes"
        value={formData.sellingPriceDT}
        onValueChange={({ floatValue }) => handleChange('sellingPriceDT', floatValue)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
      <NumericFormat
        className="my-input"
        customInput={TextField}
        thousandSeparator
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        name="buyingPriceDT"
        label="Purchase duties and taxes"
        value={formData.buyingPriceDT}
        onValueChange={({ floatValue }) => handleChange('buyingPriceDT', floatValue)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        type="number"
        name="salesVATCode"
        label="Sales VAT code"
        value={formData.salesVATCode}
        onChange={(event) => handleChange('salesVATCode', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        type="number"
        name="purchaseATCode"
        label="Purchase VAT code"
        value={formData.purchaseVATCode}
        onChange={(event) => handleChange('purchaseVATCode', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        name="collectionRef"
        label="Collection Ref"
        value={formData.collectionRef}
        onChange={(event) => handleChange('collectionRef', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        name="deliveryRef"
        label="Delivery Ref"
        value={formData.deliveryRef}
        onChange={(event) => handleChange('deliveryRef', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
                />
                
        <TextField
        name="billNumber"
        label="Bill of Lading/Air Way Bill"
        value={formData.billNumber}
        onChange={(event) => handleChange('billNumber', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
                />
                                
        <TextField
        name="vesselName"
        label="Vessel Name"
        value={formData.vesselName}
        onChange={(event) => handleChange('vesselName', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
                />
        <DatePicker
          className="my-input"
          name={"eTD"}
          label="Estimated Date of Departure"
          value={formData.eTD}
          onChange={(date) => handleChange('eTD', date)}
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
          format="dd/MM/yyyy"
        />            
        <DatePicker
          className="my-input"
          name={"eTA"}
          label="Estimated Date of Arrival"
          value={formData.eTA}
          onChange={(date) => handleChange('eTA', date)}
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
          format="dd/MM/yyyy"
                />      
          {/* add/remove containers */}      

    {/* Render container fields */}
    <div key={0} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
          <TextField
            name="container0"
            label="Container Number"
            value={formData.containers[0]}
            onChange={(event) => handleChange('containers', event.target.value, 0)}
            sx={{ flex: 1, backgroundColor: 'white' }}
            margin="dense"
            // Other props
          />

          <IconButton aria-label="add" onClick={addContainer}>
            <AddIcon />
          </IconButton>
        </Stack>
      </div>
      {/* Remaining container fields */}
      {formData.containers.slice(1).map((container, index) => (
        <div key={index + 1} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
            <TextField
              name={`container${index + 1}`}
              label="Container Number"
              value={container}
              onChange={(event) => handleChange('containers', event.target.value, index + 1)}
              sx={{ flex: 1, backgroundColor: 'white' }}
              margin="dense"
              // Other props
            />
            <IconButton aria-label="remove" onClick={() => removeContainer(index + 1)}>
              <RemoveIcon />
            </IconButton>
          </Stack>
        </div>
      ))}


            </div>
        {formData.supplierBooking.map((booking, index) => (
          <div className='supplier-section' key={index}>
          <MemoizedAutoCompleteBookings
            className="my-input"
            apiUrl={`${URI}/suppliers/autocomplete?q=`}
            id="supplier"
            name={`supplierName-${index}`} // Use a unique identifier for each supplier's name
            label="Supplier Name"
            value={booking.supplierName}
            onNewNameChange={(newValue) => handleNewNameChange(newValue, ['supplierBooking', index, 'supplierName'])}
          />
            <TextField
              name={`supplierBooking[${index}].supplierContactName`}
              label="Supplier Contact Name"
              value={booking.supplierContactName}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCargoDetails`}
              label="Supplier Cargo Details (Description of goods)"
              value={booking.supplierCargoDetails}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
             <TextField
              type="number"
              name={`supplierBooking[${index}].supplierPriceBeforeROE`}
              label="Supplier Price (in suppliers currency)"
              value={booking.supplierPriceBeforeROE}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <Select
              className="my-input"
              name="supplierBookingCurrency"
              label="Supplier Currency"
              value={booking.supplierBookingCurrency}
              onChange={(event) => handleChange('supplierBookingCurrency', event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            >
              <MenuItem value="gbp">GBP</MenuItem>
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="eur">EUR</MenuItem>
            </Select>
            <TextField
              type="number"
              name={`supplierBooking[${index}].supplierROE`}
              label="Rate of Exchange"
              value={booking.supplierROE}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierBookingPrice`}
              label="Supplier Booking Price"
              value={booking.supplierBookingPrice}
              disabled
              fullWidth
              margin="dense"
            />

             <div>
              <Button variant="contained" color="primary" onClick={handleOpenPopup}>
                Add New Address
              </Button>
              <AddressFormPopup open={isPopupOpen} onClose={handleClosePopup} />
              {/* Your AddBookings page content */}
            </div>
          <MemoizedAutoCompleteBookings
            className="my-input"
              apiUrl={`${URI}/addresses/autocomplete?q=`}
            id="address"
            name={`addressName-${index}`} // Use a unique identifier for each supplier's name
            label="Supplier Collection Address Name"
            value={booking.supplierCollectionAddressName}
            popupClosed={popupClosed}
            onNewNameChange={(newSupplierAddressValue) => handleSupplierCollectionAddressNameChange(newSupplierAddressValue, ['supplierBooking', index, 'supplierCollectionAddressName'])}
          />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionAddress1`}
              label="Supplier Collection Address 1"
              value={booking.supplierCollectionAddress1}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionAddress2`}
              label="Supplier Collection Address 2"
              value={booking.supplierCollectionAddress2}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionAddress3`}
              label="Supplier Collection Address 3"
              value={booking.supplierCollectionAddress3}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionCity`}
              label="Supplier Collection City"
              value={booking.supplierCollectionCity}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionPostcode`}
              label="Supplier Collection Postcode / zipcode"
              value={booking.supplierCollectionPostcode}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionCountry`}
              label="Supplier Collection Country"
              value={booking.supplierCollectionCountry}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <DatePicker
              className="my-input"
              name={`supplierBooking[${index}].supplierCollectionDate`}
              label="Collection Date"
              value={booking.supplierCollectionDate}
              onChange={(date) => handleChange('supplierCollectionDate', date)}
              sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
              margin="dense"
              format="dd/MM/yyyy"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionInstructions`}
              label="Supplier Collection Instructions"
              value={booking.supplierCollectionInstructions}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionRef`}
              label="Supplier Collection Reference"
              value={booking.supplierCollectionRef}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
          <MemoizedAutoCompleteBookings
              className="my-input"
              apiUrl={`${URI}/addresses/autocomplete?q=`}
            id="address"
            name={`addressName-${index}`} // Use a unique identifier for each supplier's name
            label="Supplier Delivery Address Name"
            value={booking.supplierDeliveryAddressName}
            onNewNameChange={(newSupplierAddressValue) => handleSupplierDeliveryAddressNameChange(newSupplierAddressValue, ['supplierBooking', index, 'supplierDeliveryAddressName'])}
          />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryAddress1`}
              label="Supplier Delivery Address 1"
              value={booking.supplierDeliveryAddress1}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryAddress2`}
              label="Supplier Delivery Address 2"
              value={booking.supplierDeliveryAddress2}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryAddress3`}
              label="Supplier Delivery Address 3"
              value={booking.supplierDeliveryAddress3}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryCity`}
              label="Supplier Delivery City"
              value={booking.supplierDeliveryCity}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryPostcode`}
              label="Supplier Delivery Postcode"
              value={booking.supplierDeliveryPostcode}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryCountry`}
              label="Supplier Delivery Country"
              value={booking.supplierDeliveryCountry}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <DatePicker
              className="my-input"
              name={`supplierBooking[${index}].supplierDeliveryDate`}
              label="Delivery Date"
              value={booking.supplierDeliveryDate}
              onChange={(date) => handleChange('supplierDeliveryDate', date)}
              sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
              margin="dense"
              format="dd/MM/yyyy"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryInstructions`}
              label="Supplier Delivery Instructions"
              value={booking.supplierDeliveryInstructions}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryRef`}
              label="Supplier Delivery Ref"
              value={booking.supplierDeliveryRef}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />

          </div>
        
        ))}

      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleAddSupplierBooking}>
          Add Supplier Booking
        </Button>
      </Box>

      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>

        </>
      )}

      </form>
        </LocalizationProvider>
        
      </Box>
  );
};

  
export default AddBookings;

