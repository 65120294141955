import { Link,  } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, Typography } from '@mui/material';
const Home = () => {
  return (
    <div className="home">
            <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="80%"
        mx="auto"
        bgcolor="rgba(240, 240, 240, 0.8)"
        p={4}
      >
        
      <h2>
        Home
        </h2>
        <Typography variant="body1">
          This is our new order entry system.
          <br></br>
          Please use the navbar.
          <br></br>
          You can now add addresses, which can be reused. To add an address, please make sure you give it a name to reference.
          <br></br>
          These can be added to customers, suppliers or collection/delivery addresses.
        </Typography>
    </Box>

    </div>
  )
}
export default Home