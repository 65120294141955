import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios'; // Use Axios or any other HTTP client for making API requests
import { Select, MenuItem, Container, Typography, Box, Grid, TextField, Button, InputLabel, ListItem, ListItemText, Divider } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO, isDate } from 'date-fns';
const URI = process.env.REACT_APP_URI



const TrackingDetails = () => {
  const url = window.location.pathname;
  const parts = url.split('/'); // Split the URL by '/'
  const jplRef = parts[parts.length - 1]; // Get the last part of the URL
  
  const [trackingData, setTrackingData] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // For toggling edit mode for main data
  const [updatesEditMode, setUpdatesEditMode] = useState([]); // For handling edit mode for updates

  useEffect(() => {
    // Fetch tracking data from the backend
    const fetchTrackingData = async () => {
      try {
        const response = await axios.get(`${URI}/tracking/${jplRef}`); // Replace with your API endpoint
        console.log('response', response.data.singleTracking);
        setTrackingData(response.data.singleTracking);
        setUpdatesEditMode(new Array(response.data.singleTracking.updateList.length).fill(false));
      } catch (error) {
        console.error('Error fetching tracking data:', error);
      }
    };
    // Fetch data only if jplRef exists
    if (jplRef) {
      fetchTrackingData();
    }
  }, [jplRef]); // Add jplRef as a dependency


  const handleFieldChange = (fieldPath, value, index = null) => {
    setTrackingData((prevTrackingData) => {
      const updatedData = { ...prevTrackingData };
  
      // Split the fieldPath into an array of keys to access nested fields
      const keys = fieldPath.split('.');
      
      // If the field is in the updateList array
      if (keys[0] === 'updateList') {
        const updateListIndex = index !== null ? index : 0; // If index is null, use 0 by default
        if (
          updateListIndex >= 0 &&
          updateListIndex < updatedData.updateList.length &&
          keys.length > 1
        ) {
          const field = keys.slice(1).join('.'); // Get the field name within the update object
          const update = { ...updatedData.updateList[updateListIndex] };
  
          // Update the specific field within the update object
          update[field] = value;
  
          // Update the specific update within the updateList array
          updatedData.updateList[updateListIndex] = update;
        }
      } else {
        // If the field is outside updateList (direct fields in trackingData)
        let currentField = updatedData;
        for (let i = 0; i < keys.length - 1; i++) {
          if (currentField[keys[i]] === undefined) {
            return prevTrackingData; // Field path doesn't exist in the object, return previous state
          }
          currentField = currentField[keys[i]];
        }
  
        // Update the value of the specified field
        currentField[keys[keys.length - 1]] = value;
      }
  
      return updatedData;
    });
  };
  

  const [collected, setCollected] = useState(trackingData?.collected || 0);

  const handleCollectedChange = (newValue) => {
    setCollected(newValue);
    setTrackingData((prevTrackingData) => ({
      ...prevTrackingData,
      collected: newValue,
    }));
    console.log('trackingData',trackingData);
  };
  
  const handleDeliveredChange = (newDeliveredValue) => {
    console.log('newDeliveredValue',newDeliveredValue);
    setDelivered(newDeliveredValue);
    setTrackingData((prevTrackingData) => ({
      ...prevTrackingData,
      delivered: newDeliveredValue,
    }));
    console.log('trackingData',trackingData);
  };
  
  
  
  const [delivered, setDelivered] = useState(trackingData?.delivered || 0);
  
  let collectedStatus = null;
  let deliveredStatus = null;
  
  if (trackingData) {
    collectedStatus = trackingData.collected === 1 ? 'Yes' : 'No';
    deliveredStatus = trackingData.delivered === 1 ? 'Yes' : 'No';
  }
  

  const formatDate = (dateString) => {
    // Assuming dateString contains a valid date string or a Date object
    const formattedDate = new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return formattedDate;
  };

  const handleEditMainData = () => {
    setIsEditing(true);
  };

  const handleEditUpdate = (index) => {
    console.log('index',index);
    const newUpdatesEditMode = [...updatesEditMode];
    console.log('newUpdatesEditMode',newUpdatesEditMode);
    newUpdatesEditMode[index] = true;
    setUpdatesEditMode(newUpdatesEditMode);
  };

  const handleRemoveUpdate = (index) => {
    const updatedUpdates = trackingData.updateList.filter((_, i) => i !== index);
    setTrackingData({ ...trackingData, updateList: updatedUpdates });
  };

  const handleAddUpdate = () => {
    const todayDate = new Date(); // Get today's date as a Date object
    const newUpdate = {
      updateTitle: '',
      updateDescription: '',
      updatedBy: trackingData.userId.userName,
      dateOfAction: todayDate,
      updateDate: todayDate, // Adding today's date to the new update
      // Add other properties for the new update
    };
  
    const updatedUpdates = [...trackingData.updateList, newUpdate];
    const newUpdatesEditMode = [...updatesEditMode, true]; // Set edit mode to true for the newly added update
    
    setTrackingData({ ...trackingData, updateList: updatedUpdates });
    setUpdatesEditMode(newUpdatesEditMode);
  };
  
  

  const handleSaveChanges = async () => {
    console.log('trackingData',trackingData)
    // Implement your logic to save changes
      // Check if any update in the list has an empty updateTitle
    const hasEmptyUpdateTitle = trackingData.updateList.some(update => !update.updateTitle);
    
    if (hasEmptyUpdateTitle) {
      window.alert('Please provide an update before saving.');
      return; // Don't proceed with saving if any update has an empty title
    }
    try {
      // setTrackingData((prevTrackingData) => ({
      //   ...prevTrackingData,
      // }));
      await axios.patch(`${URI}/tracking/${jplRef}`, trackingData);
      window.location.reload(); // Reload the page after patch request
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };
  const renderUpdates = (updateList) => {
    return (
      <Grid container spacing={2}>
        {updateList.map((update, index) => (
          <Grid item xs={12} key={index}>
            <ListItem>
              {updatesEditMode[index] ? (
                  <div>
                    <TextField
                      label="Update Title"
                      value={update.updateTitle}
                      onChange={(e) => handleUpdateFieldChange(e.target.value, 'updateTitle', index)}
                      sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                      margin="dense"
                    />
                    <TextField
                      label="Update Description"
                      value={update.description}
                      onChange={(e) => handleUpdateFieldChange(e.target.value, 'updateDescription', index)}
                      sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                      margin="dense"
                  />
                    <TextField
                      label="Updated by"
                      value={update.updatedBy}
                      onChange={(e) => handleUpdateFieldChange(e.target.value, 'updatedBy', index)}
                      sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                      margin="dense"
                  />
                  <DatePicker
                    className="my-input"
                    name="dateOfAction"
                    label="Date of action"
                    value={update.dateOfAction || null}
                    onChange={(date) => handleUpdateFieldChange(date, 'dateOfAction', index)}
                    sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                    margin="dense"
                    format="dd/MM/yyyy"
                  />
                  <DatePicker
                    className="my-input"
                    name="updateDate"
                    label="Update Date"
                    value={update.updateDate }
                    onChange={(date) => handleUpdateFieldChange(date,'updateDate', index)}
                    sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                    margin="dense"
                    format="dd/MM/yyyy"
                  />
                    {/* Add other text fields for editing */}
                  <Button onClick={() => handleSaveChanges()}>Save</Button>
                  <Button onClick={() => handleRemoveUpdate(index)}>Remove Update</Button>
                  </div>
              ) : (
                <Grid container>
                {/* ListItemText taking up 10 columns */}
                <Grid item xs={10}>
                  <Box sx={{ border: '1px solid #000', padding: '8px' }}>
                    <ListItemText
                      primary={`Update: ${update.updateTitle}`}
                      secondary={
                        <>
                          <Typography>Notes: {update.updateDescription}</Typography>
                          <Typography>Date of action: {formatDate(update.dateOfAction)}</Typography>
                          <Typography>Updated on: {formatDate(update.updateDate)}</Typography>
                          <Typography>Updated by: {update.updatedBy}</Typography>
                        </>
                      }
                    />
                  </Box>
                </Grid>
                {/* Buttons taking up 2 columns */}
                <Grid item xs={2}>
                  <Button onClick={() => handleEditUpdate(index)}>Edit Update</Button>
                  <Button onClick={() => handleRemoveUpdate(index)}>Remove Update</Button>
                </Grid>
              </Grid>
            )}
            {index !== updateList.length - 1 && <Divider />} {/* Add divider except for the last item */}
          </ListItem>
        </Grid>
        ))}
      </Grid>
    );
  };
  
  const handleUpdateFieldChange = (value, field, index) => {
    const updatedUpdates = [...trackingData.updateList];
    let fieldValue = value;
  
    if (value instanceof Date) {
      fieldValue = value.toISOString(); // Convert Date object to ISO string
    }
  
    updatedUpdates[index][field] = fieldValue;
    setTrackingData({ ...trackingData, updateList: updatedUpdates });
  };
  
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="80%"
      mx="auto"
      bgcolor="rgba(240, 240, 240, 0.8)"
      p={4}
    >

    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
          <div>
            {/* The previous conditional rendering is refactored for better readability */}
          {trackingData ? (
            <>
            {/* Tracking ID Section */}
            <Typography variant="h5">
              {isEditing ? (
                <TextField
                  value={trackingData.jplRef} 
                  fullWidth
                  disabled
                  sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                  margin="dense"
                />
              ) : (
                <>
                  Tracking ID: {trackingData.jplRef}
                </>
              )}
              </Typography>
                {/* Shipment Details Section which are blacnked out for editting as booking data only*/}
                {isEditing ? (
                <Grid container direction="column">
                  <TextField label="Shipment Type" value={trackingData.booking.orderType}
                    fullWidth
                    disabled
                    sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                    margin="dense" />
                  <TextField label="Collection Point" value={trackingData.booking.collectionPoint}
                      fullWidth
                      disabled
                    sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                    margin="dense" />
                  <TextField label="Delivery Point" value={trackingData.booking.deliveryPoint}
                      fullWidth
                      disabled
                    sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                    margin="dense" />
                  <TextField label="Description" value={trackingData.booking.description} 
                      fullWidth
                      disabled
                    sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                    margin="dense"/>
                </Grid>
              ) : (
                <>
                  <Typography>Shipment Type: {trackingData.booking.orderType}</Typography>
                  <Typography>Collection Point: {trackingData.booking.collectionPoint}</Typography>
                  <Typography>Delivery Point: {trackingData.booking.deliveryPoint}</Typography>
                  <Typography>Description: {trackingData.booking.description}</Typography>
                </>
              )}
              {/* Date and Status Section */}
              <Typography>
                {isEditing ? (
                  <Grid container direction="column">
                      <DatePicker
                        label="Collection Date"
                        value={new Date(trackingData.collectedDate)} 
                        onChange={(date) => handleFieldChange('collectedDate', date)}
                        fullWidth
                        sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                        margin="dense"
                        format="dd/MM/yyyy"
                      />
                <InputLabel id="collected-label">Collected?</InputLabel>
                   <Select
                    labelId="collected-label"
                    value={trackingData.collected}
                    onChange={(event) => handleCollectedChange(event.target.value)}
                    fullWidth
                    sx={{
                      width: '100%',
                      backgroundColor: 'white', // Adjust the color as needed
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                    margin="dense"
                  >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>

                      <DatePicker
                        label="Delivery Date"
                        value={new Date(trackingData.deliveredDate)} 
                        onChange={(date) => handleFieldChange('deliveredDate', date)}
                        fullWidth
                        sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                        margin="dense"
                        format="dd/MM/yyyy"
                      />
                  <InputLabel id="delivered-label">Delivered?</InputLabel>
                    <Select
                    labelId="delivered-label"
                    value={trackingData.delivered}
                    onChange={(event) => handleDeliveredChange(event.target.value)}
                    fullWidth
                    sx={{
                      width: '100%',
                      backgroundColor: 'white', // Adjust the color as needed
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                    margin="dense"
                  >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Typography>Collection Date: {formatDate(trackingData.collectedDate)}</Typography>
                    <Typography>Collected? {collectedStatus}</Typography>
                    <Typography>Delivery Date: {formatDate(trackingData.deliveredDate)}</Typography>
                    <Typography>Delivered? {deliveredStatus}</Typography>
                  </Grid>
                )}
                </Typography>
                {/* User Section */}
                {trackingData.userId && (
                  <Typography>
                    {isEditing ? (
                      <>
                        User: <TextField
                          value={trackingData.userId.userName} 
                          fullWidth
                          disabled
                          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
                          margin="dense"
                        />
                      </>
                    ) : (
                        <><Grid container direction='column'>
                          <Typography>
                            User: {trackingData.userId.userName}
                          </Typography>
                        </Grid>
                          <div>
                          <Button
                            sx={{ width: '100%', backgroundColor: 'red', color: 'white', marginTop: '8px', marginBottom: '8px' }}
                            onClick={handleEditMainData}
                          >
                            Edit
                          </Button>

                          </div>
                        </>
                    )}
                  </Typography>
                )}
                {/* Update List Section */}
                {trackingData.updateList && (
                <>
                  <Typography variant="h6">Updates:</Typography>
                    {renderUpdates(trackingData.updateList || [])}
                    <Divider /> {/* Add divider after each tracking entry */}
                    <Button onClick={handleAddUpdate}>Add Update</Button>
                  </>
                )}
              </>
            ) : (
              <Typography>Loading...</Typography>
            )}
            {/* Save Changes and Back to Tracking buttons */}
            <Button onClick={handleSaveChanges}>Save Changes</Button>
            <Button onClick={() => (window.location.href = '/tracking')}>
              Back to Tracking Page
            </Button>
          </div>
        </Container>
      </LocalizationProvider>    
    </Box>
  );
}

export default TrackingDetails