import React, { useState } from 'react';
//import { useHistory } from 'react-router-dom';
import { Box, TextField, Select, MenuItem } from '@mui/material';
import { ivory } from '../constants/colors'
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import AxiosRateLimit from 'axios-rate-limit'
const URI = process.env.REACT_APP_URI


const instance = AxiosRateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 1000
})

const AddQuotationClauses = () => {
  const [formData, setFormData] = useState({
    orderType: 'Select from the below options',
    clause: '',
  });

  const orderTypeOptions = [
    'Select from the below options',
    'Roadfreight Full load - Import',
    'Roadfreight Groupage - Import',
    'Roadfreight Abnormal load - Import',
    'Roadfreight Full load - Export',
    'Roadfreight Groupage - Export',
    'Roadfreight Abnormal load - Export',
    'Roadfreight Full load - Domestic',
    'Roadfreight Groupage - Domestic',
    'Roadfreight Abnormal load - Domestic',
    'Seafreight FCL - Import',
    'Seafreight LCL - Import',
    'Seafreight FCL - Export',
    'Seafreight LCL - Export',
    'Seafreight FCL - Cross Trade',
    'Seafreight LCL - Cross Trade',
    'Seafreight Out-of-gauge - Import',
    'Seafreight Out-of-gauge - Export',
    'Seafreight Out-of-gauge - Cross trade',
    'Seafreight - Charter',
    'Airfreight - Import',
    'Airfreight - Export',
    'Courier - Import',
    'Courier - Export',
    'Courier - Domestic',
    'Customs Clearance only - Export entry',
    'Customs Clearance only - Import entry',
    'Storage',
    'Packing',
    'Container Loading',
    'Container unloading',
    'Freight insurance',
    'Crane Hire',
    'Other logistics service'
  ];
  
    const handleChange = (name, value, index = 0) => {
      console.log('changing name: ', name);
      setFormData((prevData) => {
        const updatedData = { ...prevData };
        console.log(updatedData);
    
        
          // Handle changes in other fields
          updatedData[name] = value;
    
        return updatedData;
      });
    };

    const handleSubmit = () => {
    console.log('submitted');
    // Perform your POST request here using formData
    instance.post(`${URI}/quotation-clauses/new-quotation-clause`, formData)
      .then((response) => {
        console.log('POST request successful:', response.data);
        // Add any success message or further actions here
              // Display an alert message
      window.alert('Quotation Clause Submitted!');
      // Redirect to the '/bookings' page
      window.location.href = '/quotation-clauses';
      })
      .catch((error) => {
        console.error('Error while making the POST request:', error);
        // Handle the error or show an error message to the user
      });
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="80%"
      mx="auto"
      bgcolor={ivory}
      p={4}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form>

        <Select
          className="my-input"
          name="orderType"
          label="Order Type"
          value={formData.orderType}
          onChange={(event) => handleChange('orderType', event.target.value)}
          sx={{ width: '100%', backgroundColor: 'white' }}
          margin="dense"
        >
          {orderTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          </Select>
          <TextField
            name="clause"
            label="Quotation Clauses"
            value={formData.clause}
            onChange={(event) => handleChange('clause', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
            multiline
            rows={10}  // You can adjust the number of rows as needed
          />

      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
      </form>
      </LocalizationProvider>
      </Box>
  )
}

export default AddQuotationClauses