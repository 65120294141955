// Invoices.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS
import { isToday } from 'date-fns';
const URI = process.env.REACT_APP_URI

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 30 },
  {
    field: 'jplRef',
    headerName: 'Shipment Ref',
    width: 100,
    editable: true,
  },
  {
    field: 'invoiceNumber',
    headerName: 'Invoice number',
    width: 100,
    editable: true,
  },
  {
    field: 'customerName',
    headerName: 'Customer name',
    width: 150,
    editable: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
    editable: true,
  },
  {
    field: 'collectionPoint',
    headerName: 'Collecting from',
    width: 120,
    editable: true,
  },
  {
    field: 'deliveryPoint',
    headerName: 'Delivery point',
    width: 120,
    editable: true,
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date',
    type: 'date',
    width: 120,
    editable: true,
    valueGetter: (params) => {
      const dateValue = params.row.invoiceDate;
      return dateValue ? new Date(dateValue).toISOString().slice(0, 10) : '';
    },
    valueFormatter: (params) => {
      const dateValue = params.value;
      return dateValue ? new Date(dateValue).toISOString().slice(0, 10) : '';
    },
  },
  {
    field: 'invoiceDueDate',
    headerName: 'Invoice Due Date',
    type: 'date',
    width: 120,
    editable: true,
    valueGetter: (params) => {
      const dateValue = params.row.invoiceDueDate;
      return dateValue ? new Date(dateValue).toISOString().slice(0, 10) : '';
    },
    valueFormatter: (params) => {
      const dateValue = params.value;
      return dateValue ? new Date(dateValue).toISOString().slice(0, 10) : '';
    },
  },
  {
    field: 'daysOutstanding',
    headerName: 'Days Outstanding',
    type: 'number',
    width: 70,
    editable: true
  },
  {
    field: 'sellingPriceNett',
    headerName: 'nett',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'vATAmount',
    headerName: 'VAT',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'sellingPriceGross',
    headerName: 'gross',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'userName',
    headerName: 'User',
    width: 80,
    editable: true,
  },
  {
    field: 'invoiceMonth',
    headerName: 'Month',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'invoiceYear',
    headerName: 'Year',
    type: 'number',
    width: 70,
    editable: true,
  }
];



function Invoices() {
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState({});

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(`${URI}/invoicing/get-invoices`)
        setInvoices(response.data.allInvoices)
        setLoading(false)
      } catch (error) {
        setError('Error fetching invoices'); // Set the error message
        setLoading(false); // Set loading to false even on error
     }
    }
    fetchInvoices()
  }, []);
  
  const navigate = useNavigate();

  const handleRowClick = async (params) => {
    const invoiceNumber = params.row.invoiceNumber;
    
    try {
      const response = await axios.get(`${URI}/invoicing/single-invoice/${invoiceNumber}`);
      const invoiceData = response.data; // Assuming the response contains invoice details
      console.log('invoiceData',invoiceData);
      // You can navigate to the details page and pass the invoice data as a route parameter
      navigate(`/invoice-details/${invoiceNumber}`, { state: { invoiceData } });
    } catch (error) {
      console.error('Error fetching invoice data', error);
    }
  };

  const handleCheckboxChange = (params) => {
    const rowId = params.invoiceNumber;
    setSelectedRowIds((prevSelectedRowIds) => ({
      ...prevSelectedRowIds,
      [rowId]: !prevSelectedRowIds[rowId], // Toggle selection status
    }));
  };
 // Log selected jplRefs when checkboxes are selected/deselected
 useEffect(() => {
  const selectedInvoiceNumbers = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);
  console.log('Selected Invoices:', selectedInvoiceNumbers);
 }, [selectedRowIds]);
  
const deleteSelectedInvoices = async () => {
  const selectedInvoiceNumbers = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);

  if (selectedInvoiceNumbers.length === 0) {
    return; // No invoices selected, do nothing
  }

  confirmAlert({
    title: 'Confirm Deletion',
    message: `Are you sure you want to delete invoices ${selectedInvoiceNumbers.join(', ')}?`,
    buttons: [
      {
        label: 'Yes',
        onClick: async () => {
          try {
            for (const invoiceNumber of selectedInvoiceNumbers) {
              await axios.delete(`${URI}/invoicing/single-invoice/${invoiceNumber}`);
            }
            // Update the UI or state as needed after successful deletion
            // For example, you can refresh the page here
            window.location.reload();
          } catch (error) {
            console.error('Error deleting invoices:', error);
          }
        },
      },
      {
        label: 'No',
        onClick: () => {
          // Do nothing, user canceled
        },
      },
    ],
  });
};

const [selectedInvoices, setSelectedInvoices] = useState([]);

const calculateInvoiceTotals = () => {
  const selectedRows = invoices.filter((invoice) => selectedRowIds[invoice.invoiceNumber]);

  const totalNett = selectedRows.reduce((acc, curr) => acc + curr.sellingPriceNett, 0);
  const totalVAT = selectedRows.reduce((acc, curr) => acc + curr.vATAmount, 0);
  const totalGross = selectedRows.reduce((acc, curr) => acc + curr.sellingPriceGross, 0);

  const message = `
    Total Selling Price Nett: £${totalNett}
    Total VAT: £${totalVAT}
    Total Selling Price Gross: £${totalGross}
  `;

  window.alert(message);

  // Set the selected invoices for reference or any further processing
  setSelectedInvoices(selectedRows);
};

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  function formatDateString(date) {
    if (!date) {
      return ''; // Return an empty string if the date is null or undefined
    }
  
    const formattedDate = new Date(date);
    return !isNaN(formattedDate) ? formattedDate.toISOString().slice(0, 10) : ''; // Format the date to YYYY-MM-DD or return an empty string if invalid
  }
  

// Convert the invoices array to the format expected by the Datagrid
const rows = invoices.map((invoice, index) => {
  // Calculate the difference in milliseconds between the due date and current date
  const dueDate = new Date(invoice.invoiceDueDate);
  const currentDate = new Date();
  const differenceInMs = currentDate - dueDate;

  // Convert milliseconds to days
  const daysOutstanding = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

  return {
    id: index + 1,
    jplRef: invoice.jplRef,
    invoiceNumber: invoice.invoiceNumber,
    customerName: invoice.customerName,
    description: invoice.description,
    collectionPoint: invoice.collectionPoint,
    deliveryPoint: invoice.deliveryPoint,
    invoiceDate: formatDateString(invoice.invoiceDate),
    invoiceDueDate: formatDateString(invoice.invoiceDueDate),
    daysOutstanding: daysOutstanding > 0 ? daysOutstanding : 0, // Ensuring it's not negative
    sellingPriceNett: invoice.sellingPriceNett,
    vATAmount: invoice.vATAmount,
    sellingPriceGross: invoice.sellingPriceGross,
    userName: invoice.userName,
    invoiceMonth: invoice.invoiceMonth,
    invoiceYear: invoice.invoiceYear
  };
});

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
          wrap: true, // Enable text wrapping within cells
        }))}
        checkboxSelection
        disableRowSelectionOnClick
        onSelectionModelChange={(newSelection) => {
          // No need to set selectedRowIds here
        }}
        selectionModel={Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId])}
        onRowClick={handleRowClick}
        onCellClick={(params) => {
          if (params.field === '__check__') {
            handleCheckboxChange(params.row);
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        autoHeight
        columnHeaderHeight={70} // Set the header height (2 rows * default row height, assuming default is 48px)
      />
      <Link to="/add-invoice"> {/* Add the Link component wrapping the button */}
        <button>Create New Invoice</button>
      </Link>
      <button onClick={deleteSelectedInvoices}>Delete Invoice</button>
      <button onClick={calculateInvoiceTotals}>Calculate Invoice totals</button>
    </Box>
  );
}

export default Invoices;
