import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useReactToPrint } from 'react-to-print'; // Add this import
import Dialog from '@mui/material/Dialog'; // Add this import
import DialogTitle from '@mui/material/DialogTitle'; // Add this import
import DialogContent from '@mui/material/DialogContent'; // Add this import
import DialogActions from '@mui/material/DialogActions'; // Add this import
import { ivory, sage } from '../constants/colors';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
const URI = process.env.REACT_APP_URI

console.log('loading Quotation clause Details');
const QuotationClauseForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location state', location.state);
  const iniQuoteData = location.state.quoteData;
  console.log('iniQuoteData',iniQuoteData);
  // const iniQuotationClause = iniQuoteData.quotationClause

  const iniOrderType = iniQuoteData.orderType || 'Select from the below options'
  const { id } = useParams();
  const [formData, setFormData] = useState({

    orderType: iniOrderType,
    clause: iniQuoteData.clause,
  })

  const orderTypeOptions = [
    'Select from the below options',
    'Roadfreight Full load - Import',
    'Roadfreight Groupage - Import',
    'Roadfreight Abnormal load - Import',
    'Roadfreight Full load - Export',
    'Roadfreight Groupage - Export',
    'Roadfreight Abnormal load - Export',
    'Roadfreight Full load - Domestic',
    'Roadfreight Groupage - Domestic',
    'Roadfreight Abnormal load - Domestic',
    'Seafreight FCL - Import',
    'Seafreight LCL - Import',
    'Seafreight FCL - Export',
    'Seafreight LCL - Export',
    'Seafreight FCL - Cross Trade',
    'Seafreight LCL - Cross Trade',
    'Seafreight Out-of-gauge - Import',
    'Seafreight Out-of-gauge - Export',
    'Seafreight Out-of-gauge - Cross trade',
    'Seafreight - Charter',
    'Airfreight - Import',
    'Airfreight - Export',
    'Courier - Import',
    'Courier - Export',
    'Courier - Domestic',
    'Customs Clearance only - Export entry',
    'Customs Clearance only - Import entry',
    'Storage',
    'Packing',
    'Container Loading',
    'Container unloading',
    'Freight insurance',
    'Crane Hire',
    'Other logistics service'
  ];
  useEffect(() => {
    const fetchQuoteData = async () => {
      try {
        const response = await axios.get(`${URI}/quotation-clauses/${id}`);
        const quoteData = response.data;
        console.log('quoteData in frontend: ', quoteData);
        const singleQuote = quoteData.singleQuotationClause;
        const orderType = singleQuote.orderType || 'Select from the below options';
        const clause = singleQuote.clause;
        
        const initialFormData = {
          orderType: orderType,
          clause: clause,
        };
        console.log(initialFormData);
        setFormData(initialFormData);
        console.log(formData);
      } catch (error) {
        console.error('Error fetching quote details:', error);
      }
    };
  
    fetchQuoteData();
  }, []);

  const handleChange = (name, value, index = 0) => {
    console.log('changing name: ', name);
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      console.log(updatedData);
  
      
        // Handle changes in other fields
        updatedData[name] = value;
  
      return updatedData;
    });
  };
  const handleSubmit = () => {
    console.log('submitted');
    // Perform your POST request here using formData
    axios.patch(`${URI}/quotation-clauses/edit-quotation-clause/${id}`, formData)
      .then((response) => {
        console.log('POST request successful:', response.data);
        // Add any success message or further actions here
              // Display an alert message
      window.alert('Quotation Clause Submitted!');
      // Redirect to the '/bookings' page
      window.location.href = '/quotation-clauses';
      })
      .catch((error) => {
        console.error('Error while making the POST request:', error);
        // Handle the error or show an error message to the user
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="80%"
      mx="auto"
      bgcolor={ivory}
      p={4}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form>
        <Select
          className="my-input"
          name="orderType"
          label="Order Type"
          value={formData.orderType}
          onChange={(event) => handleChange('orderType', event.target.value)}
          sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
            disabled
        >
          {orderTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          </Select>
          <TextField
            name="clause"
            label="Quotation Clauses"
            value={formData.clause}
            onChange={(event) => handleChange('clause', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
            multiline
            rows={10}  // You can adjust the number of rows as needed
          />

      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>

        </form>
      </LocalizationProvider>
    </Box>
    


)
};

  
export default QuotationClauseForm;
