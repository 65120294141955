// Bookings.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS
const URI = process.env.REACT_APP_URI

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 30 },
  {
    field: 'jplRef',
    headerName: 'Shipment Ref',
    width: 100,
    editable: true,
  },
  {
    field: 'orderType',
    headerName: 'Shipment type',
    width: 200,
    editable: true,
  },
  {
    field: 'customerName',
    headerName: 'Customer name',
    width: 150,
    editable: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
    editable: true,
  },
  {
    field: 'collectionPoint',
    headerName: 'Collecting from',
    width: 120,
    editable: true,
  },
  {
    field: 'deliveryPoint',
    headerName: 'Delivery point',
    width: 120,
    editable: true,
  },
  {
    field: 'collectionDate',
    headerName: 'Collecting on',
    type: 'date',
    width: 120,
    editable: true,
    valueGetter: (params) => {
      const dateValue = params.row.collectionDate;
      return dateValue ? new Date(dateValue).toISOString().slice(0, 10) : '';
    },
    valueFormatter: (params) => {
      const dateValue = params.value;
      return dateValue ? new Date(dateValue).toISOString().slice(0, 10) : '';
    },
  },
  {
    field: 'deliveryDate',
    headerName: 'Delivering on',
    type: 'date',
    width: 120,
    editable: true,
    valueGetter: (params) => {
      const dateValue = params.row.deliveryDate;
      return dateValue ? new Date(dateValue).toISOString().slice(0, 10) : '';
    },
    valueFormatter: (params) => {
      const dateValue = params.value;
      return dateValue ? new Date(dateValue).toISOString().slice(0, 10) : '';
    },
  },  
  {
    field: 'sellingPrice',
    headerName: 'Sale cost',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'buyingPrice',
    headerName: 'Buy cost',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'profit',
    headerName: 'profit',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'userName',
    headerName: 'User',
    width: 80,
    editable: true,
  },
  {
    field: 'bookingMonth',
    headerName: 'Month',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'bookingYear',
    headerName: 'Year',
    type: 'number',
    width: 70,
    editable: true,
  }
];



function Bookings() {
  const [bookings, setBookings] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState({});

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${URI}/booking/get-bookings`)
        setBookings(response.data.allBookings)
        setLoading(false)
      } catch (error) {
        setError('Error fetching bookings'); // Set the error message
        setLoading(false); // Set loading to false even on error
     }
    }
    fetchBookings()
  }, []);
  
  const navigate = useNavigate();

  const handleRowClick = async (params) => {
    const jplRef = params.row.jplRef;
    
    try {
      const response = await axios.get(`${URI}/booking/get-single-booking/${jplRef}`);
      const bookingData = response.data; // Assuming the response contains booking details
      console.log('bookingData',bookingData);
      // You can navigate to the details page and pass the booking data as a route parameter
      navigate(`/booking-details/${jplRef}`, { state: { bookingData } });
    } catch (error) {
      console.error('Error fetching booking data', error);
    }
  };

  const handleCheckboxChange = (params) => {
    const rowId = params.jplRef;
    setSelectedRowIds((prevSelectedRowIds) => ({
      ...prevSelectedRowIds,
      [rowId]: !prevSelectedRowIds[rowId], // Toggle selection status
    }));
  };
 // Log selected jplRefs when checkboxes are selected/deselected
 useEffect(() => {
  const selectedJPLRefs = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);
  console.log('Selected JPLRefs:', selectedJPLRefs);
 }, [selectedRowIds]);
  
const deleteSelectedBookings = async () => {
  const selectedJPLRefs = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);

  if (selectedJPLRefs.length === 0) {
    return; // No bookings selected, do nothing
  }

  confirmAlert({
    title: 'Confirm Deletion',
    message: `Are you sure you want to delete bookings ${selectedJPLRefs.join(', ')}?`,
    buttons: [
      {
        label: 'Yes',
        onClick: async () => {
          try {
            for (const jplRef of selectedJPLRefs) {
              await axios.delete(`${URI}/booking/${jplRef}`);
            }
            // Update the UI or state as needed after successful deletion
            // For example, you can refresh the page here
            window.location.reload();
          } catch (error) {
            console.error('Error deleting bookings:', error);
          }
        },
      },
      {
        label: 'No',
        onClick: () => {
          // Do nothing, user canceled
        },
      },
    ],
  });
};


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  function formatDateString(date) {
    if (!date) {
      return ''; // Return an empty string if the date is null or undefined
    }
  
    const formattedDate = new Date(date);
    return !isNaN(formattedDate) ? formattedDate.toISOString().slice(0, 10) : ''; // Format the date to YYYY-MM-DD or return an empty string if invalid
  }

  // Convert the bookings array to the format expected by the Datagrid
  const rows = bookings.map((booking, index) => ({
    id: index + 1,
    jplRef: booking.jplRef,
    orderType: booking.orderType,
    customerName: booking.customerName,
    description: booking.description,
    collectionPoint: booking.collectionPoint,
    collectionDate: formatDateString(booking.collectionDate),
    deliveryPoint: booking.deliveryPoint,
    deliveryDate: formatDateString(booking.deliveryDate),
    sellingPrice: booking.sellingPrice,
    buyingPrice: booking.buyingPrice,
    profit: booking.profit,
    userName: booking.userName,
    bookingMonth: booking.bookingMonth,
    bookingYear: booking.bookingYear
  }));
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onSelectionModelChange={(newSelection) => {
          // No need to set selectedRowIds here
        }}
        selectionModel={Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId])}
        onRowClick={handleRowClick}
        onCellClick={(params) => {
          if (params.field === '__check__') {
            handleCheckboxChange(params.row);
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
      <Link to="/add-booking"> {/* Add the Link component wrapping the button */}
        <button>Create New Booking</button>
      </Link>
        <button onClick={deleteSelectedBookings}>Delete Booking</button>
    </Box>
  );
}

export default Bookings;
