import { BrowserRouter, Routes, Route } from 'react-router-dom'

// pages and components
import Home from './pages/Home'
import Addresses from './pages/Addresses'
import AddAddress from './pages/AddAddress'
import AddQuote from './pages/AddQuote'
import AddBooking from './pages/AddBooking'
import AddQuotationClause from './pages/AddQuotationClause'
import Bookings from './pages/Bookings'
import Enquiries from './pages/Enquiries'
import QuotationClauses from './pages/QuotationClauses'
import BookingDetails from './pages/BookingDetails'
import AddressDetails from './pages/AddressDetails'
import EnquiryDetails from './pages/EnquiryDetails'
import QuotationClauseDetails from './pages/QuotationClauseDetails'
import Tracking from './pages/Tracking'
import AddTracking from './pages/AddTracking'
import TrackingDetails from './pages/TrackingDetails'
import Invoices from './pages/Invoices'
import AddInvoice from './pages/AddInvoice'
import Navbar from './components/Navbar';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
 
// Theme code
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(64, 96, 121, 1)',
    },
    secondary: {
      main: 'rgba(142, 175, 190, 1)',
    },
    success: {
      main: 'rgba(196, 197, 166, 1)'
    },
    light: {
      main: 'rgba(250, 248, 235, 1)'
    }
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Navbar />
          <div className="pages">
            <Routes>
                <Route
                  path='/'
                  element={<Home />}
              />
                <Route
                  path='/add-address'
                  element={<AddAddress/>}
                />
                <Route
                  path='/addresses'
                  element={<Addresses />}
                />
                <Route
                  path="/address-details/:addressname"
                  element={<AddressDetails />}
              />
              <Route
                  path='/add-quote'
                  element={<AddQuote />}
                />
                <Route
                  path='/enquiries'
                  element={<Enquiries />}
                />
                <Route
                  path='/bookings'
                  element={<Bookings />}
                />
                <Route
                  path='/add-booking'
                  element={<AddBooking />}
                />
                <Route
                  path="/booking-details/:bookingref"
                  element={<BookingDetails />}
                />
                <Route
                  path="/quote-details/:quotenumber"
                  element={<EnquiryDetails />}
              />
              <Route
                  path="/quotation-clauses"
                  element={<QuotationClauses />}
              />
              <Route
                  path="/quotation-clauses/:id"
                  element={<QuotationClauseDetails />}
              />
              <Route
                  path="/add-quotation-clause"
                  element={<AddQuotationClause />}
                />
            <Route
                  path="/add-tracking"
                  element={<AddTracking />}
                />

            <Route
                  path="/tracking"
                  element={<Tracking />}
                />

            <Route
                  path="/tracking-details/:jplRef"
                  element={<TrackingDetails />}
              />
              <Route
                  path="/invoices"
                  element={<Invoices />}
                />
                <Route
                    path="/add-invoice"
                    element={<AddInvoice />}
                  />
            </Routes>
        </div>
        </BrowserRouter>
        </div>
      </ThemeProvider>
  );
}

export default App;
