import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, TextField, Select, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import AxiosRateLimit from 'axios-rate-limit'
import { parseISO } from 'date-fns';

import AutoCompleteBookings from '../components/AutoCompleteBookings'
const URI = process.env.REACT_APP_URI

const instance = AxiosRateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 1000
})

// Wrap the AutoCompleteBookings component with React.memo
const MemoizedAutoCompleteBookings = React.memo(AutoCompleteBookings);


const BookingForm = () => {
  const location = useLocation();
  const bookingData = location.state.bookingData;
  const singleBooking = bookingData.singleBooking
  const jplRef = typeof singleBooking.jplRef === 'number' ? singleBooking.jplRef : parseInt(singleBooking.jplRef);
  console.log(typeof jplRef);
  const collectionDate = singleBooking.collectionDate ? parseISO(singleBooking.collectionDate) : null;
  const deliveryDate = singleBooking.deliveryDate ? parseISO(singleBooking.deliveryDate) : null;
  const initOrderType = singleBooking.orderType || 'Select from the below options'
  const updatedSupplierBooking = singleBooking.supplierBooking.map((booking) => {
    const supplierCollectionDate = booking.supplierCollectionDate ? parseISO(booking.supplierCollectionDate) : null;
    const supplierDeliveryDate = booking.supplierDeliveryDate ? parseISO(booking.supplierDeliveryDate) : null
    return {
      supplierName: booking.supplierName || null,
      supplierContactName: booking.supplierContactName || null,
      supplierCargoDetails: booking.supplierCargoDetails || null,
      supplierPriceBeforeROE: booking.supplierPriceBeforeROE || null,
      supplierBookingCurrency: booking.supplierBookingCurrency || null,
      supplierROE: booking.supplierROE || null,
      supplierBookingPrice: booking.supplierBookingPrice || null,
      supplierCollectionAddressName: booking.supplierCollectionAddressName || null,
      supplierCollectionAddress1: booking.supplierCollectionAddress1 || null,
      supplierCollectionAddress2: booking.supplierCollectionAddress2 || null,
      supplierCollectionAddress3: booking.supplierCollectionAddress3 || null,
      supplierCollectionCity: booking.supplierCollectionCity || null,
      supplierCollectionPostcode: booking.supplierCollectionPostcode || null,
      supplierCollectionCountry: booking.supplierCollectionCountry || null,
      supplierCollectionDate: supplierCollectionDate || null,
      supplierCollectionInstructions: booking.supplierCollectionInstructions || null,
      supplierCollectionRef: booking.supplierCollectionRef || null,
      supplierDeliveryAddressName: booking.supplierDeliveryAddressName || null,
      supplierDeliveryAddress1: booking.supplierDeliveryAddress1 || null,
      supplierDeliveryAddress2: booking.supplierDeliveryAddress2 || null,
      supplierDeliveryAddress3: booking.supplierDeliveryAddress3 || null,
      supplierDeliveryCity: booking.supplierDeliveryCity || null,
      supplierDeliveryPostcode: booking.supplierDeliveryPostcode || null,
      supplierDeliveryCountry: booking.supplierDeliveryCountry || null,
      supplierDeliveryDate: supplierDeliveryDate || null,
      supplierDeliveryInstructions: booking.supplierDeliveryInstructions || null,
      supplierDeliveryRef: booking.supplierDeliveryRef || null,
    };
  });

  const [formData, setFormData] = useState({
    jplRef: jplRef,
    quoteRef: singleBooking.quoteRef,
    orderType: initOrderType,
    customerName: bookingData.customerName,
    customerPurchaseOrderNumber: singleBooking.customerPurchaseOrderNumber,
    description: singleBooking.description,
    collectionPoint: singleBooking.collectionPoint,
    deliveryPoint: singleBooking.deliveryPoint,
    collectionDate: collectionDate,
    deliveryDate: deliveryDate,
    sellingPrice: singleBooking.sellingPrice,
    sellingPriceCurrency: singleBooking.sellingPriceCurrency,
    sellingPriceROE: singleBooking.sellingPriceROE,
    sellingPriceDT: singleBooking.sellingPriceDT,
    buyingPriceDT: singleBooking.buyingPriceDT,
    salesVATCode: singleBooking.salesVATCode,
    purchaseVATCode: singleBooking.purchaseVATCode,
    collectionRef: singleBooking.collectionRef,
    deliveryRef: singleBooking.deliveryRef,
    userName: bookingData.userName,
    billNumber: singleBooking.billNumber,
    containers: singleBooking.containers,
    vesselName: singleBooking.vesselName,
    eTD: singleBooking.eTD || null,
    eTA: singleBooking.eTA || null,
    supplierBooking: updatedSupplierBooking
  })
  console.log('supplierName', formData.supplierBooking[0]);
  console.log('collectionDate',collectionDate);
  const orderTypeOptions = [
    'Select from the below options',
    'Roadfreight Full load - Import',
    'Roadfreight Groupage - Import',
    'Roadfreight Abnormal load - Import',
    'Roadfreight Full load - Export',
    'Roadfreight Groupage - Export',
    'Roadfreight Abnormal load - Export',
    'Roadfreight Full load - Domestic',
    'Roadfreight Groupage - Domestic',
    'Roadfreight Abnormal load - Domestic',
    'Seafreight FCL - Import',
    'Seafreight LCL - Import',
    'Seafreight FCL - Export',
    'Seafreight LCL - Export',
    'Seafreight FCL - Cross Trade',
    'Seafreight LCL - Cross Trade',
    'Seafreight Out-of-gauge - Import',
    'Seafreight Out-of-gauge - Export',
    'Seafreight Out-of-gauge - Cross trade',
    'Seafreight - Charter',
    'Airfreight - Import',
    'Airfreight - Export',
    'Courier - Import',
    'Courier - Export',
    'Courier - Domestic',
    'Customs Clearance only - Export entry',
    'Customs Clearance only - Import entry',
    'Storage',
    'Packing',
    'Container Loading',
    'Container unloading',
    'Freight insurance',
    'Crane Hire',
    'Other logistics service'
  ];
  const addContainer = () => {

    if (formData.containers.length === 0) {
      // If the initial container is empty, replace it with two containers
      setFormData({ ...formData, containers: ['', ''] });
    } else {
      setFormData({ ...formData, containers: [...formData.containers, ''] });
    }
    console.log('Formdata when adding container: ', formData);
  };
  
  
  
  
  

  const removeContainer = (indexToRemove) => {

    if (formData.containers.length > 0) {
      const updatedContainers = formData.containers.filter((_, index) => index !== indexToRemove);
      setFormData({ ...formData, containers: updatedContainers });
    }
    console.log('Formdata when removing container: ', formData);
  };

  const handleChange = (name, value, index = 0) => {
    console.log('handleChange');
    console.log('name', name);
    console.log('value', value);
    console.log('formData before handleChange', formData);
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      console.log('prevData',prevData);
      console.log('updatedData',updatedData);
      if (name.startsWith('supplierBooking[')) {
        // Handle changes in supplierBooking fields
        const supplierBookingIndex = parseInt(name.match(/\[(\d+)\]/)[1]);
          if (supplierBookingIndex >= 0 && supplierBookingIndex < updatedData.supplierBooking.length) {
          let supplierBooking = { ...updatedData.supplierBooking[supplierBookingIndex] };
  
          if (name.endsWith('supplierPriceBeforeROE') || name.endsWith('supplierROE')) {
            // Calculate supplierBookingPrice when supplierPriceBeforeROE or supplierROE changes
            const nameParts = name.split('.');
            const propertyName = nameParts.pop(); // Get the final property name
            supplierBooking[propertyName] = value;
            const supplierPriceBeforeROE = parseFloat(supplierBooking.supplierPriceBeforeROE);
            const supplierROE = parseFloat(supplierBooking.supplierROE);
            supplierBooking.supplierBookingPrice = parseFloat((supplierPriceBeforeROE / supplierROE).toFixed(2));
          } else {
            const nameParts = name.split('.');
            const propertyName = nameParts.pop(); // Get the final property name

            supplierBooking[propertyName] = value;
          }
  
          updatedData.supplierBooking[supplierBookingIndex] = supplierBooking;
        }
      } else if (name === 'containers') {
        const updatedContainers = [...updatedData.containers];
        updatedContainers[index] = value;
        console.log('value if name is containers', value);
        return { ...updatedData, containers: updatedContainers };
      } else {
        updatedData[name] = value;
      }
  
      return updatedData;
    });
  
    console.log('Formdata when handle change: ', formData);
  };
  const handleNewNameChange = (newValue, fieldName) => {
    // Disable further callbacks until enabled again
    setFormData((prevData) => {
      if (Array.isArray(fieldName)) {
        // Updating a nested field within supplierBooking array
        const [arrayName, index, nestedField] = fieldName;
        console.log('prevData', prevData)
        if (
          prevData[arrayName] &&
          Array.isArray(prevData[arrayName]) &&
          prevData[arrayName][index]
          &&
          prevData[arrayName][index][nestedField] !== newValue
        ) { 
          const updatedSupplierBooking = [...prevData[arrayName]];
          console.log('updatedSupplierBooking', updatedSupplierBooking);
          const selectedSupplierName = newValue; // Assuming the name property holds the selected name
          console.log('selectedSupplierName',selectedSupplierName);
          updatedSupplierBooking[index] = {
            ...updatedSupplierBooking[index],
            [nestedField]: selectedSupplierName,
          };
          console.log('updatedSupplierBooking[index]',updatedSupplierBooking[index]);

          return {
            ...prevData,
            [arrayName]: updatedSupplierBooking,
          };
        } else {
          console.log('error')
          console.log(prevData[arrayName]);
          console.log(Array.isArray(prevData[arrayName]));
          console.log(prevData[arrayName][index]);
          console.log(prevData[arrayName][index][nestedField]);
        }
      } else {
        // Updating a single property
        console.log('single property');
        if (prevData[fieldName] !== newValue) {
          return {
            ...prevData,
            [fieldName]: newValue,
          };
        }
      }
      return prevData;
    });
  }
    
  const handleAddSupplierBooking = () => {
    setFormData((prevData) => ({
      ...prevData,
      supplierBooking: [
        ...prevData.supplierBooking,
        {
          supplierName: '',
          supplierContactName: '',
          supplierCargoDetails: '',
          supplierPriceBeforeROE: 0,
          supplierBookingCurrency: 'gbp',
          supplierROE: 1,
          supplierBookingPrice: 0,
          supplierCollectionAddressName: '',
        supplierCollectionAddress1: '',
        supplierCollectionAddress2: '',
        supplierCollectionAddress3: '',
        supplierCollectionCity: '',
        supplierCollectionPostcode: '',
        supplierCollectionCountry: '',
          supplierCollectionDate: null,
          supplierCollectionInstructions: '',
          supplierCollectionRef: '',
          supplierDeliveryAddressName: '',
        supplierDeliveryAddress1: '',
        supplierDeliveryAddress2: '',
        supplierDeliveryAddress3: '',
        supplierDeliveryCity: '',
        supplierDeliveryPostcode: '',
        supplierDeliveryCountry: '',
          supplierDeliveryDate: null,
          supplierDeliveryInstructions: '',
          supplierDeliveryRef: '',
        },
      ],
    }));
  };
  const handleSubmit = () => {
    console.log('submitted');
  
    const updatedFormData = { ...formData };
  
    // Check if jplRef exists and is a number
    if (updatedFormData.jplRef !== undefined && updatedFormData.jplRef !== null) {
      const jplRefNumber = Number(updatedFormData.jplRef);
      if (!isNaN(jplRefNumber)) {
        updatedFormData.jplRef = jplRefNumber; // Update jplRef with the parsed number
        console.log("jplRef wasn't a number so had to parse int");
      } else {
        // Handle the case where jplRef is not a valid number
        console.error('jplRef is not a valid number:', updatedFormData.jplRef);
        // You might choose to show an error message to the user or handle it in another way
      }
    } else {
      // Handle the case where jplRef is undefined or null
      console.error('jplRef is undefined or null:', updatedFormData.jplRef);
      // You might choose to show an error message to the user or handle it in another way
    }
  
    // Check and set other fields that may be undefined to null
    if (updatedFormData.deliveryDate === undefined) {
      updatedFormData.deliveryDate = null;
    }
    // Add similar checks for other fields if needed
    if (updatedFormData.orderType === 'Select from the below options' || formData.orderType === undefined) {
      window.alert('Select shipment type')
      return
    } 
  
    console.log('formData before sending: ', updatedFormData);
  
    // Rest of your code remains unchanged...
    // Perform your POST request using updatedFormData
    instance
      .patch(`${URI}/booking/new-booking`, updatedFormData)
      .then((response) => {
        // Handle successful response
        console.log('PATCH request successful:', response.data);
        // Display success message or redirect to another page
        window.alert('Booking Updated!');
        window.location.href = '/bookings';
      })
      .catch((error) => {
        // Handle error
        console.error('Error while making the PATCH request:', error);
        console.log('This is what the post request was supposed to be: ', updatedFormData);
        // Show error message to the user
      });
  };
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="80%"
      mx="auto"
      bgcolor="rgba(240, 240, 240, 0.8)"
      p={4}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form>
        <TextField
            name="quoteRef"
            label="Quote Ref"
            value={formData.quoteRef}
            onChange={(event) => handleChange('quoteRef', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <div className="autocomplete-container">
        <Select
          className="my-input"
          name="orderType"
          label="Order Type"
          value={formData.orderType}
          onChange={(event) => handleChange('orderType', event.target.value)}
          sx={{ width: '100%', backgroundColor: 'white' }}
          margin="dense"
        >
          {orderTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          </Select>
          </div>
          {/* <AutoCompleteExample /> */}
          <AutoCompleteBookings
            className="my-input"
            apiUrl={`${URI}/customers/autocomplete?q=`}
            id="customer"
            name="customerName"
            formData={formData}
            label="Customer"
            onNewNameChange={(newValue) => handleNewNameChange(newValue, 'customerName')}
            />
      <TextField
        name="customerName"
        label="Temporary placeholder for customer name. Copy to autocomplete for now until issue is fixed."
        value={formData.customerName}
        onChange={(event) => handleChange('customerName', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />

      <TextField
        name="customerPurchaseOrderNumber"
        label="Customer PO"
        value={formData.customerPurchaseOrderNumber}
        onChange={(event) => handleChange('customerPurchaseOrderNumber', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <TextField
        name="description"
        label="Description"
        value={formData.description}
        onChange={(event) => handleChange('description', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <TextField
        name="collectionPoint"
        label="Collection Point"
        value={formData.collectionPoint}
        onChange={(event) => handleChange('collectionPoint', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <TextField
        name="deliveryPoint"
        label="Delivery Point"
        value={formData.deliveryPoint}
        onChange={(event) => handleChange('deliveryPoint', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
          />
          <div className="autocomplete-container">
      <DatePicker
        className="my-input"
        name="collectionDate"
        label="Collection Date"
        value={formData.collectionDate}
        onChange={(date) => handleChange('collectionDate', date)}
        sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
        margin="dense"
        format="dd/MM/yyyy"
            />
          </div>
          <div className="autocomplete-container">
          <DatePicker
            className="my-input"
            name="deliveryDate"
            label="Delivery Date"
            value={formData.deliveryDate}
            onChange={(date) => handleChange('deliveryDate', date)}
            sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
            margin="dense"
            format="dd/MM/yyyy"
          />
          </div>
          <div className="autocomplete-container">
      <NumericFormat
        className="my-input"
        customInput={TextField}
        thousandSeparator
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        name="sellingPrice"
        label="Sale cost"
        value={formData.sellingPrice}
        onValueChange={({ floatValue }) => handleChange('sellingPrice', floatValue)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
            />
          </div>
          <div className="autocomplete-container">
      <Select
        className="my-input"
        name="sellingPriceCurrency"
        label="Currency"
        value={formData.sellingPriceCurrency}
        onChange={(event) => handleChange('sellingPriceCurrency', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      >
        <MenuItem value="gbp">GBP</MenuItem>
        <MenuItem value="usd">USD</MenuItem>
        <MenuItem value="eur">EUR</MenuItem>
            </Select>
            </div>
      <TextField
        type="number"
        name="sellingPriceROE"
        label="Rate of Exchange"
        value={formData.sellingPriceROE}
        onChange={(event) => handleChange('sellingPriceROE', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      />
      <NumericFormat
        className="my-input"
        customInput={TextField}
        thousandSeparator
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        name="sellingPriceDT"
        label="Sale duties and taxes"
        value={formData.sellingPriceDT}
        onValueChange={({ floatValue }) => handleChange('sellingPriceDT', floatValue)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
      <NumericFormat
        className="my-input"
        customInput={TextField}
        thousandSeparator
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        name="buyingPriceDT"
        label="Purchase duties and taxes"
        value={formData.buyingPriceDT}
        onValueChange={({ floatValue }) => handleChange('buyingPriceDT', floatValue)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        type="number"
        name="salesVATCode"
        label="Sales VAT code"
        value={formData.salesVATCode}
        onChange={(event) => handleChange('salesVATCode', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        type="number"
        name="purchaseATCode"
        label="Purchase VAT code"
        value={formData.purchaseVATCode}
        onChange={(event) => handleChange('purchaseVATCode', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        name="collectionRef"
        label="Collection Ref"
        value={formData.collectionRef}
        onChange={(event) => handleChange('collectionRef', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        name="deliveryRef"
        label="Delivery Ref"
        value={formData.deliveryRef}
        onChange={(event) => handleChange('deliveryRef', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
        />
        <TextField
        name="userName"
        label="User name"
        value={formData.userName}
        onChange={(event) => handleChange('userName', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          
          <TextField
        name="billNumber"
        label="Bill of Lading/Air Way Bill"
        value={formData.billNumber}
        onChange={(event) => handleChange('billNumber', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
                />
                                
        <TextField
        name="vesselName"
        label="Vessel Name"
        value={formData.vesselName}
        onChange={(event) => handleChange('vesselName', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
                />
        <DatePicker
          className="my-input"
          name={"eTD"}
          label="Estimated Date of Departure"
          value={formData.eTD}
          onChange={(date) => handleChange('eTD', date)}
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
          format="dd/MM/yyyy"
        />            
        <DatePicker
          className="my-input"
          name={"eTA"}
          label="Estimated Date of Arrival"
          value={formData.eTA}
          onChange={(date) => handleChange('eTA', date)}
          sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
          margin="dense"
          format="dd/MM/yyyy"
                />      
          {/* add/remove containers */}      

    {/* Render container fields */}
    <div key={0} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
          <TextField
            name="container0"
            label="Container Number"
            value={formData.containers[0]}
            onChange={(event) => handleChange('containers', event.target.value, 0)}
            sx={{ flex: 1, backgroundColor: 'white' }}
            margin="dense"
            // Other props
          />

          <IconButton aria-label="add" onClick={addContainer}>
            <AddIcon />
          </IconButton>
        </Stack>
      </div>
      {/* Remaining container fields */}
      {formData.containers.slice(1).map((container, index) => (
        <div key={index + 1} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
            <TextField
              name={`container${index + 1}`}
              label="Container Number"
              value={container}
              onChange={(event) => handleChange('containers', event.target.value, index + 1)}
              sx={{ flex: 1, backgroundColor: 'white' }}
              margin="dense"
              // Other props
            />
            <IconButton aria-label="remove" onClick={() => removeContainer(index + 1)}>
              <RemoveIcon />
            </IconButton>
          </Stack>
        </div>
      ))}






        {formData.supplierBooking.map((booking, index) => (
          <div key={index}>
          <MemoizedAutoCompleteBookings
            apiUrl={`${URI}/suppliers/autocomplete?q=`}
            id="supplier"
            name={`supplierName-${index}`} // Use a unique identifier for each supplier's name
            label={`Supplier Name ${index + 1}`}
            value={booking.supplierName}
            onNewNameChange={(newValue) => handleNewNameChange(newValue, ['supplierBooking', index, 'supplierName'])}
          />
            <TextField
              name={`supplierBooking[${index}].supplierContactName`}
              label={`Supplier ${index + 1} Contact Name`}
              value={booking.supplierContactName}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCargoDetails`}
              label={`Supplier ${index + 1} Cargo Details (Description of goods)`}
              value={booking.supplierCargoDetails}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
             <TextField
              type="number"
              name={`supplierBooking[${index}].supplierPriceBeforeROE`}
              label={`Supplier ${index + 1} Price (in suppliers currency)`}
              value={booking.supplierPriceBeforeROE}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <Select
              className="my-input"
              name="supplierBookingCurrency"
              label={`Supplier ${index + 1} Currency`}
              value={booking.supplierBookingCurrency}
              onChange={(event) => handleChange('supplierBookingCurrency', event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            >
              <MenuItem value="gbp">GBP</MenuItem>
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="eur">EUR</MenuItem>
            </Select>
            <TextField
              type="number"
              name={`supplierBooking[${index}].supplierROE`}
              label="Rate of Exchange"
              value={booking.supplierROE}
              onChange={(event) =>
                handleChange('supplierROE', event.target.value, index)
              }
              fullWidth
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierBookingPrice`}
              label={`Supplier ${index + 1} Booking Price`}
              value={booking.supplierBookingPrice}
              disabled
              fullWidth
              margin="dense"
            />
           <TextField
              name={`supplierBooking[${index}].supplierCollectionAddressName`}
              label="Supplier Collection Address Name"
              value={booking.supplierCollectionAddressName}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionAddress1`}
              label="Supplier Collection Address 1"
              value={booking.supplierCollectionAddress1}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionAddress2`}
              label="Supplier Collection Address 2"
              value={booking.supplierCollectionAddress2}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionAddress3`}
              label="Supplier Collection Address 3"
              value={booking.supplierCollectionAddress3}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionCity`}
              label="Supplier Collection City"
              value={booking.supplierCollectionCity}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionPostcode`}
              label="Supplier Collection Postcode / zipcode"
              value={booking.supplierCollectionPostcode}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionCountry`}
              label="Supplier Collection Country"
              value={booking.supplierCollectionCountry}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <DatePicker
              name={`supplierBooking[${index}].supplierCollectionDate`}
              label={`Supplier ${index + 1} Collection Date`}
              value={booking.supplierCollectionDate}
              onChange={(date) => handleChange('supplierCollectionDate', date)}
              sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
              margin="dense"
              format="dd/MM/yyyy"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionInstructions`}
              label={`Supplier ${index + 1} Collection Instructions`}
              value={booking.supplierCollectionInstructions}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierCollectionRef`}
              label={`Supplier ${index + 1} Collection Reference`}
              value={booking.supplierCollectionRef}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryAddressName`}
              label="Supplier Delivery Address Name"
              value={booking.supplierDeliveryAddressName}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryAddress1`}
              label="Supplier Delivery Address 1"
              value={booking.supplierDeliveryAddress1}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryAddress2`}
              label="Supplier Delivery Address 2"
              value={booking.supplierDeliveryAddress2}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryAddress3`}
              label="Supplier Delivery Address 3"
              value={booking.supplierDeliveryAddress3}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryCity`}
              label="Supplier Delivery City"
              value={booking.supplierDeliveryCity}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryPostcode`}
              label="Supplier Delivery Postcode"
              value={booking.supplierDeliveryPostcode}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryCountry`}
              label="Supplier Delivery Country"
              value={booking.supplierDeliveryCountry}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <DatePicker
              className="my-input"
              name={`supplierBooking[${index}].supplierDeliveryDate`}
              label={`Supplier ${index + 1} Delivery Date`}
              value={booking.supplierDeliveryDate}
              onChange={(date) => handleChange('supplierDeliveryDate', date)}
              sx={{ width: '100%', backgroundColor: 'white', marginTop: '8px', marginBottom: '8px' }}
              margin="dense"
              format="dd/MM/yyyy"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryInstructions`}
              label={`Supplier ${index + 1} Delivery Instructions`}
              value={booking.supplierDeliveryInstructions}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierBooking[${index}].supplierDeliveryRef`}
              label={`Supplier ${index + 1} Delivery Ref`}
              value={booking.supplierDeliveryRef}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />

          </div>
        ))}

      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleAddSupplierBooking}>
          Add Supplier Booking
        </Button>
      </Box>

      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
      </form>
      </LocalizationProvider>
      </Box>
  );
};

  
export default BookingForm;

