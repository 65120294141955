// Addresses.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS
const URI = process.env.REACT_APP_URI

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 30 },
  {
    field: 'addressName',
    headerName: 'Address Name',
    width: 100,
    editable: true,
  },
  {
    field: 'address1',
    headerName: 'Address line 1',
    width: 200,
    editable: true,
  },
  {
    field: 'address2',
    headerName: 'Address line 2',
    width: 200,
    editable: true,
  },
  {
    field: 'address3',
    headerName: 'Address line 3',
    width: 200,
    editable: true,
  },
  {
    field: 'city',
    headerName: 'Town/City',
    width: 120,
    editable: true,
  },
  {
    field: 'postcode',
    headerName: 'Postcode/Zipcode',
    width: 120,
    editable: true,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 120,
    editable: true,
  },
  {
    field: 'userName',
    headerName: 'User',
    width: 80,
    editable: true,
  }
];



function Addresses() {
  const [addresses, setAddresses] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState({});

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(`${URI}/addresses/get-addresses`)
        setAddresses(response.data.addresses)
        setLoading(false)
      } catch (error) {
        setError('Error fetching addresses'); // Set the error message
        setLoading(false); // Set loading to false even on error
     }
    }
    fetchAddresses()
  }, []);
  
  const navigate = useNavigate();

  const handleRowClick = async (params) => {
    const addressname = params.row.addressName;
    console.log('addressname', addressname);
    
    try {
      const response = await axios.get(`${URI}/addresses/${addressname}`);
      const addressData = response.data; // Assuming the response contains address details
      console.log('addressData', addressData);
      // You can navigate to the details page and pass the address data as a route parameter
      navigate(`/address-details/${addressname}`, { state: { addressData } });
    } catch (error) {
      console.error('Error fetching address data', error);
    }
  };

  const handleCheckboxChange = (params) => {
    const rowId = params.addressName;
    setSelectedRowIds((prevSelectedRowIds) => ({
      ...prevSelectedRowIds,
      [rowId]: !prevSelectedRowIds[rowId], // Toggle selection status
    }));
  };
 // Log selected jplRefs when checkboxes are selected/deselected
 useEffect(() => {
  const selectedAddressNames = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);
  console.log('Selected Address Names:', selectedAddressNames);
 }, [selectedRowIds]);
  
const deleteSelectedAddresses = async () => {
  const selectedAddressNames = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);

  if (selectedAddressNames.length === 0) {
    return; // No addresses selected, do nothing
  }

  confirmAlert({
    title: 'Confirm Deletion',
    message: `Are you sure you want to delete addresses ${selectedAddressNames.join(', ')}?`,
    buttons: [
      {
        label: 'Yes',
        onClick: async () => {
          try {
            for (const addressname of selectedAddressNames) {
              await axios.delete(`${URI}/addresses/${addressname}`);
            }
            // Update the UI or state as needed after successful deletion
            // For example, you can refresh the page here
            window.location.reload();
          } catch (error) {
            console.error('Error deleting addresses:', error);
          }
        },
      },
      {
        label: 'No',
        onClick: () => {
          // Do nothing, user canceled
        },
      },
    ],
  });
};


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  // Convert the addresses array to the format expected by the Datagrid
  const rows = addresses.map((address, index) => ({
    id: index + 1,
    addressName: address.addressName,
    address1: address.address1,
    address2: address.address2,
    address3: address.address3,
    city: address.city,
    postcode: address.postcode,
    country: address.country,
    userName: address.userName,
  }));
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onSelectionModelChange={(newSelection) => {
          // No need to set selectedRowIds here
        }}
        selectionModel={Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId])}
        onRowClick={handleRowClick}
        onCellClick={(params) => {
          if (params.field === '__check__') {
            handleCheckboxChange(params.row);
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
      <Link to="/add-address"> {/* Add the Link component wrapping the button */}
        <button>Create New Address</button>
      </Link>
        <button onClick={deleteSelectedAddresses}>Delete Address</button>
    </Box>
  );
}

export default Addresses;
