import React, { useState } from 'react';
//import { useHistory } from 'react-router-dom';
import { Box, TextField, Select, MenuItem } from '@mui/material';
import { ivory } from '../constants/colors'
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import AxiosRateLimit from 'axios-rate-limit'

import AutoCompleteEnquiries from '../components/AutoCompleteEnquiries'
const URI = process.env.REACT_APP_URI

// Wrap the AutoCompleteEnquiries component with React.memo
const MemoizedAutoCompleteEnquiries = React.memo(AutoCompleteEnquiries);

const instance = AxiosRateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 1000
})

const AddQuotes = () => {
  const [formData, setFormData] = useState({
    customerName: '',
    orderType: 'Select from the below options',
    description: '',
    collectionPoint: '',
    deliveryPoint: '',
    sellingPrice: 0,
    sellingPriceCurrency: 'gbp',
    quoteROE: 1,
    sellingPriceGBP: 0,
    buyingPrice: 0,
    profit: 0,
    userName: '',
    supplierQuotes: [
      {
        supplierName: '',
        supplierDescription: '', // Initially empty
        initialSupplierDescription: '', // Store the initial description
        supplierQuotePrice: 0,
        supplierQuoteCurrency: 'gbp',
        supplierQuoteROE: 1,
        supplierQuoteGBP: 0,
        isChecked: false, // Add a new field to store checkbox state
      },
    ]
  });

  const orderTypeOptions = [
    'Select from the below options',
    'Roadfreight Full load - Import',
    'Roadfreight Groupage - Import',
    'Roadfreight Abnormal load - Import',
    'Roadfreight Full load - Export',
    'Roadfreight Groupage - Export',
    'Roadfreight Abnormal load - Export',
    'Roadfreight Full load - Domestic',
    'Roadfreight Groupage - Domestic',
    'Roadfreight Abnormal load - Domestic',
    'Seafreight FCL - Import',
    'Seafreight LCL - Import',
    'Seafreight FCL - Export',
    'Seafreight LCL - Export',
    'Seafreight FCL - Cross Trade',
    'Seafreight LCL - Cross Trade',
    'Seafreight Out-of-gauge - Import',
    'Seafreight Out-of-gauge - Export',
    'Seafreight Out-of-gauge - Cross trade',
    'Seafreight - Charter',
    'Airfreight - Import',
    'Airfreight - Export',
    'Courier - Import',
    'Courier - Export',
    'Courier - Domestic',
    'Customs Clearance only - Export entry',
    'Customs Clearance only - Import entry',
    'Storage',
    'Packing',
    'Container Loading',
    'Container unloading',
    'Freight insurance',
    'Crane Hire',
    'Other logistics service'
  ];
  const handleAddSupplierQuote = () => {
    setFormData((prevData) => {
      const newSupplier = {
        supplierName: '',
        supplierDescription: prevData.description, // Set the initial supplierDescription to match the description
        initialSupplierDescription: prevData.description, // Store the initial description
        supplierQuotePrice: 0,
        supplierQuoteCurrency: 'gbp',
        supplierQuoteROE: 1,
        supplierQuoteGBP: 0,
        isChecked: false,
      };
      newSupplier.initialSupplierDescription = prevData.description; // Set the initial description
      return {
        ...prevData,
        supplierQuotes: [...prevData.supplierQuotes, newSupplier],
      };
    });
  }
  
    // const [isPopupOpen, setIsPopupOpen] = useState(false);
    // const [popupClosed, setPopupClosed] = useState(false);
  
    // const handleOpenPopup = () => {
    //   setIsPopupOpen(true);
    // };
  
    // const handleClosePopup = () => {
    //   setPopupClosed(true)
    //   setIsPopupOpen(false);
    // };
    const handleChange = (name, value, index = 0) => {
      console.log('changing name: ', name);
      setFormData((prevData) => {
        const updatedData = { ...prevData };
        console.log(updatedData);
    
        if (name.startsWith('supplierQuotes[')) {
          console.log('name starts with supplierQuotes');
          // Handle changes in supplierQuote fields
          const supplierQuoteIndex = parseInt(name.match(/\[(\d+)\]/)[1]);
          console.log(supplierQuoteIndex);
          console.log('updatedData.supplierQuotes.length', updatedData.supplierQuotes.length);
          if (supplierQuoteIndex >= 0 && supplierQuoteIndex < updatedData.supplierQuotes.length) {
            console.log('the if statement works');
            let supplierQuotes = { ...updatedData.supplierQuotes[supplierQuoteIndex] };
    
            if (name.endsWith('supplierQuotePrice') || name.endsWith('supplierQuoteROE')) {
              console.log('if suppliers price or suppliers roe');
              // Calculate supplierQuotePriceGBP when supplierQuotePrice or supplierQuoteROE changes
              const nameParts = name.split('.');
              const propertyName = nameParts.pop(); // Get the final property name

              supplierQuotes[propertyName] = value;
              const supplierQuotePrice = parseFloat(supplierQuotes.supplierQuotePrice);
              const supplierQuoteROE = parseFloat(supplierQuotes.supplierQuoteROE);
              supplierQuotes.supplierQuoteGBP = parseFloat((supplierQuotePrice / supplierQuoteROE).toFixed(2));

            } else {
              console.log('else not if suppliers price or suppliers roe');
              console.log('supplierQuotes', supplierQuotes);
              console.log('supplierQuotes.supplierName');
              console.log('supplierQuotes[name]',supplierQuotes[name]);
              // Update other supplierQuote fields
              console.log('value', value);
              console.log('name', name);
              const nameParts = name.split('.');
              const propertyName = nameParts.pop(); // Get the final property name
  
              supplierQuotes[propertyName] = value;
            }
    
            updatedData.supplierQuotes[supplierQuoteIndex] = supplierQuotes;
          }
        } else if (name === 'description') {
          // Handle changes to the description field
          updatedData[name] = value;
          
          // Update the supplierDescriptions for all supplierQuotes
          updatedData.supplierQuotes = updatedData.supplierQuotes.map((quote) => ({
            ...quote,
            supplierDescription: value, // Set supplierDescription to match the description
          }))
        } else {
          // Handle changes in other fields
          updatedData[name] = value;
        }
    
        return updatedData;
      });
    };
    const handleSupplierDescriptionChange = (value, index) => {
      setFormData((prevData) => {
        const updatedData = { ...prevData };
        if (index >= 0 && index < updatedData.supplierQuotes.length) {
          updatedData.supplierQuotes[index].supplierDescription = value;
        }
        return updatedData;
      });
    };
    
  
    const handleNewNameChange = (newValue, fieldName) => {
      console.log(newValue);
      console.log(fieldName);
      // Disable further callbacks until enabled again
      setFormData((prevData) => {
        if (Array.isArray(fieldName)) {
          // Updating a nested field within supplierQuote array
          const [arrayName, index, nestedField] = fieldName;
          console.log('prevData', prevData)
          if (
            prevData[arrayName] &&
            Array.isArray(prevData[arrayName]) &&
            prevData[arrayName][index]
            &&
            prevData[arrayName][index][nestedField] !== newValue
          ) { 
            const updatedSupplierQuote = [...prevData[arrayName]];
            console.log('updatedSupplierQuote', updatedSupplierQuote);
            const selectedSupplierName = newValue; // Assuming the name property holds the selected name
            console.log('selectedSupplierName',selectedSupplierName);
            updatedSupplierQuote[index] = {
              ...updatedSupplierQuote[index],
              [nestedField]: selectedSupplierName,
            };
            console.log('updatedSupplierQuote[index]',updatedSupplierQuote[index]);
  
            return {
              ...prevData,
              [arrayName]: updatedSupplierQuote,
            };
          } else {
            console.log('error')
            console.log(prevData[arrayName]);
            console.log(Array.isArray(prevData[arrayName]));
            console.log(prevData[arrayName][index]);
            console.log(prevData[arrayName][index][nestedField]);
          }
        } else {
          // Updating a single property
          console.log('single property');
          if (prevData[fieldName] !== newValue) {
            return {
              ...prevData,
              [fieldName]: newValue,
            };
          }
        }
        return prevData;
      });
    }
  // Handler to toggle the checkbox state
  const handleToggleCheckbox = (index) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      const supplierQuote = { ...updatedData.supplierQuotes[index] };
      supplierQuote.isChecked = !supplierQuote.isChecked;
      updatedData.supplierQuotes[index] = supplierQuote;
      return updatedData;
    });
  };

  // Calculate the total value based on checked checkboxes
  const calculateTotalValue = () => {
    return formData.supplierQuotes.reduce((total, quote) => {
      if (quote.isChecked) {
        return total + parseFloat(quote.supplierQuoteGBP);
      }
      return total;
    }, 0);
  };
  const profit = (parseFloat(formData.sellingPrice) || 0) - calculateTotalValue();
  
    const handleSubmit = () => {
    console.log('submitted');
    // Perform your POST request here using formData
    instance.post(`${URI}/quotes/new-quote`, formData)
      .then((response) => {
        console.log('POST request successful:', response.data);
        // Add any success message or further actions here
              // Display an alert message
      window.alert('Quote Submitted!');
      // Redirect to the '/bookings' page
      window.location.href = '/Enquiries';
      })
      .catch((error) => {
        console.error('Error while making the POST request:', error);
        // Handle the error or show an error message to the user
      });
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="80%"
      mx="auto"
      bgcolor={ivory}
      p={4}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form>
          <div className='first-section'>
        <AutoCompleteEnquiries
            className="my-input"
            apiUrl={`${URI}/customers/autocomplete?q=`}
            id="customer"
            name="customerName"
            label="Customer"
            onNewNameChange={(newValue) => handleNewNameChange(newValue, 'customerName')}
            />
            <div className="autocomplete-container">
        <Select
          className="my-input"
          name="orderType"
          label="Order Type"
          value={formData.orderType}
          onChange={(event) => handleChange('orderType', event.target.value)}
          sx={{ width: '100%', backgroundColor: 'white' }}
          margin="dense"
        >
          {orderTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          </Select>
          </div>
          <TextField
            name="description"
            label="Description"
            value={formData.description}
            onChange={(event) => handleChange('description', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <TextField
            name="collectionPoint"
            label="Collection Point"
            value={formData.collectionPoint}
            onChange={(event) => handleChange('collectionPoint', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <TextField
            name="deliveryPoint"
            label="Delivery Point"
            value={formData.deliveryPoint}
            onChange={(event) => handleChange('deliveryPoint', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
              />
      <NumericFormat
        className="my-input"
        customInput={TextField}
        thousandSeparator
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        name="sellingPrice"
        label="Sale cost"
        value={formData.sellingPrice}
        onValueChange={({ floatValue }) => handleChange('sellingPrice', floatValue)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
            />

          <div className="autocomplete-container">
      <Select
        className="my-input"
        name="sellingPriceCurrency"
        label="Currency"
        value={formData.sellingPriceCurrency}
        onChange={(event) => handleChange('sellingPriceCurrency', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      >
        <MenuItem value="gbp">GBP</MenuItem>
        <MenuItem value="usd">USD</MenuItem>
        <MenuItem value="eur">EUR</MenuItem>
            </Select>
            </div>
      <TextField
        type="number"
        name="quoteROE"
        label="Rate of Exchange"
        value={formData.quoteROE}
        onChange={(event) => handleChange('quoteROE', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
            />
      <TextField
        type="number"
        name="sellingPriceGBP"
        label="Selling Price in GBP"
        value={formData.sellingPriceGBP}
        disabled
        fullWidth
        margin="dense"
            />
      <TextField
        name="userName"
        label="User name"
        value={formData.userName}
        onChange={(event) => handleChange('userName', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
            />
          </div>
{/*supplier section supplier section supplier section supplier section supplier section supplier section supplier section supplier section supplier section supplier section */}
       {formData.supplierQuotes.map((quote, index) => (
         <div className="supplier-section" key={index}>
           <h2>Supplier Quote {index + 1}</h2> {/* Display the number for each section */}
           <div>
                <input
                  type="checkbox"
                  checked={quote.isChecked}
                  onChange={() => handleToggleCheckbox(index)}
                />
                <label>Add to total buying price?</label>
              </div>
           <MemoizedAutoCompleteEnquiries
            className="my-input"
              apiUrl={`${URI}/suppliers/autocomplete?q=`}
            id="supplier"
            name={`supplierName-${index}`} // Use a unique identifier for each supplier's name
            label="Supplier Name"
            value={quote.supplierName}
            onNewNameChange={(newValue) => handleNewNameChange(newValue, ['supplierQuotes', index, 'supplierName'])}
          />
            <TextField
              name={`supplierQuotes[${index}].supplierDescription`}
              label="Supplier Cargo Details (Description of goods)"
              value={quote.supplierDescription}
              onChange={(event) => handleSupplierDescriptionChange(event.target.value, index)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
             <TextField
              type="number"
              name={`supplierQuotes[${index}].supplierQuotePrice`}
              label="Supplier Price (in suppliers currency)"
              value={quote.supplierQuotePrice}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <Select
              className="my-input"
              name="supplierQuoteCurrency"
              label="Supplier Currency"
              value={quote.supplierQuoteCurrency}
              onChange={(event) => handleChange('supplierQuoteCurrency', event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            >
              <MenuItem value="gbp">GBP</MenuItem>
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="eur">EUR</MenuItem>
            </Select>
            <TextField
              type="number"
              name={`supplierQuotes[${index}].supplierQuoteROE`}
              label="Rate of Exchange"
              value={quote.supplierQuoteROE}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierQuotes[${index}].supplierQuoteGBP`}
              label="Supplier Price in GBP"
              value={quote.supplierQuoteGBP}
              disabled
              fullWidth
              margin="dense"
            />
          </div>
        ))}
        <TextField
            label="Total Buying Price in Supplier's Currency"
            value={calculateTotalValue()}
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: '100%', backgroundColor: 'white' }}

            margin="dense"
          />

          <TextField
            label="Profit"
            value={profit} // Use the calculated profit here
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: '100%', backgroundColor: 'white' }}

            margin="dense"
          />
      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleAddSupplierQuote}>
          Add Supplier Quote
        </Button>
      </Box>

      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
      </form>
      </LocalizationProvider>
      </Box>
  )
}

export default AddQuotes