import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//import { useHistory } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
const URI = process.env.REACT_APP_URI


const AddressForm = ({ onClose }) => {
  // ...
// const [country, setCountry] = useState('');
const [selectedCountry, setSelectedCountry] = useState('');
// const [cities, setCities] = useState([]); // Initialize as an empty array
// const [city, setCity] = useState(''); // Initialize with an empty string or your default value
const [countryOptions, setCountryOptions] = useState([]); // Ensure you have the country options.
const isAlphaNumericLowerCase = (str) => {
  return /^[a-z0-9]+$/.test(str);
};

const handleChange = (name, value, index = 0) => {
  console.log('changing name: ', name);
  if (name === 'addressName') {
    // Check if the entered value is valid
    if (!isAlphaNumericLowerCase(value)) {
      // You can display an error message or prevent the change if needed.
      console.log('Invalid input for addressName. It must be lowercase, contain only letters or numbers, and have no spaces.');
      return;
    }
  }
  setFormData((prevData) => {
    const updatedData = { ...prevData, [name]: value };
    console.log(updatedData);
    return updatedData;
  });
};

const [formData, setFormData] = useState({
  addressName: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  postcode: '',
  country: selectedCountry,
  userName: ''
});

useEffect(() => {
  console.log('Getting countries');
  axios.get(`${URI}/addresses/countries`)
    .then((response) => {
      const data = response.data.map((country) => country.name.common);
        data.sort();

        // Find the index of "United Kingdom"
        const ukIndex = data.indexOf("United Kingdom");

        // Move "United Kingdom" to the beginning of the array
        if (ukIndex !== -1) {
          data.splice(ukIndex, 1);
          data.unshift("United Kingdom");
        }
      setCountryOptions(data);
      console.log(response);
    })
    .catch((error) => {
      console.error('Failed to fetch data:', error);
    });
}, []);

const handleCountryChange = (event) => {
  setSelectedCountry(event.target.value);
      // Update the formData with the selected country
      setFormData((prevData) => ({
        ...prevData,
        country: event.target.value,
      }));
};
//   if (event.target.value === 'United Kingdom') {
//     // Fetch cities for the selected country
//     axios
//       .get(`${URI}/addresses/cities')
//       .then((response) => {
//         setCities(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching cities:', error);
//       });
//   } else {
//     // Reset cities if a country other than 'United Kingdom' is selected.
//     setCities([]);
//   }

// const handleCityChange = (event) => {
//   setCity(event.target.value);
// };

// useEffect(() => {
//   if (selectedCountry === 'United Kingdom') {
//     // Make a GET request to fetch towns for the selected country
//     axios
//       .get('https://towns.online-tech.co.uk/api/v1/towns?field=name_1')
//       .then((response) => {
//         setCity(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching cities:', error);
//       });
//   } else {
//     setCity([]); // Reset towns if a country other than 'United Kingdom' is selected.
//   }
// }, [selectedCountry]);



const handleSubmit = () => {
  console.log('submitted');
  console.log(formData);
  // Perform your POST request here using formData
  axios.post(`${URI}/addresses/new-address`, formData)
    .then((response) => {
      console.log('POST request successful:', response.data);
      // Add any success message or further actions here
            // Display an alert message
    window.alert('Address Submitted!');
    // Redirect to the '/bookings' page
    })
    .catch((error) => {
      console.error('Error while making the POST request:', error);
      // Handle the error or show an error message to the user
    });
  // Close the popup when the form is submitted

      onClose();
};

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="80%"
      mx="auto"
      bgcolor="rgba(240, 240, 240, 0.8)"
      p={4}
    >
     <form>
      <Typography variant="h3">
        Address form
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 3 }}>
        Please enter all details for the address here. This just logs the address so you can recall it elsewhere.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 3 }}>
        The Address Name should be a single word with no spaces. This will create a short code to be used to help find the address easier elsewhere.
      </Typography>
        <TextField
            name="addressName"
            label="Address Name"
            value={formData.addressName}
            onChange={(event) => handleChange('addressName', event.target.value)}
            required
            sx={{ width: '100%', backgroundColor: 'white', marginTop: 3 }}
          margin="dense"
        />

          <FormControl sx={{  width: '100%', backgroundColor: 'white' }} margin="dense">
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              name="country"
              label="Country"
              select
              value={selectedCountry}
              onChange={handleCountryChange}
              required
              sx={{ width: '100%', backgroundColor: 'white'}}
              margin="dense"
            >
              {countryOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        <TextField
            name="address1"
            label="Address Line 1"
            value={formData.address1}
            onChange={(event) => handleChange('address1', event.target.value)}
            required
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
        <TextField
            name="address2"
            label="Address Line 2"
            value={formData.address2}
            onChange={(event) => handleChange('address2', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
        <TextField
            name="address3"
            label="Address Line 3"
            value={formData.address3}
            onChange={(event) => handleChange('address3', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
      <Typography variant="body1" sx={{ marginTop: 3 }}>
        Please use the right town/city for this address. Spelling and accuracy is important in case we need to use the data elsewhere.
      </Typography>
          <TextField
            id="city"
            label="Town/City"
            value={formData.city}
            onChange={(event) => handleChange('city', event.target.value)}
            required
          sx={{ width: '100%', backgroundColor: 'white', marginTop: 3 }}
            margin="dense"
        />
         <Typography variant="body1" sx={{ marginTop: 3 }}>
        For UK postcodes, please use the space eg 'TS2 1RT'.
      </Typography>
          <TextField
            name="postcode"
            label="Postcode/Zipcode"
            value={formData.postcode}
            onChange={(event) => handleChange('postcode', event.target.value)}
            required
            sx={{ width: '100%', backgroundColor: 'white', marginTop: 3 }} 
            margin="dense"
        />
          <TextField
          name="userName"
          label="User name"
          value={formData.userName}
          onChange={(event) => handleChange('userName', event.target.value)}
          required
          sx={{ width: '100%', backgroundColor: 'white' }}
          margin="dense"
          />
      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Address
          </Button>
        </Box>
        </form>
        </Box>
  );
};

export default AddressForm;
