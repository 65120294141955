import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useReactToPrint } from 'react-to-print'; // Add this import
import Dialog from '@mui/material/Dialog'; // Add this import
import DialogTitle from '@mui/material/DialogTitle'; // Add this import
import DialogContent from '@mui/material/DialogContent'; // Add this import
import DialogActions from '@mui/material/DialogActions'; // Add this import
import { ivory, sage } from '../constants/colors';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import AutoCompleteEnquiries from '../components/AutoCompleteEnquiries';
import QuotationContent from '../components/QuotationContent';
import jsPDF from 'jspdf';
const URI = process.env.REACT_APP_URI

// Wrap the AutoCompleteEnquiries component with React.memo
const MemoizedAutoCompleteEnquiries = React.memo(AutoCompleteEnquiries);
console.log('loading Enquiry Details');
const QuoteForm = () => {
  const navigate = useNavigate();
  const [profit, setProfit] = useState(0); // Initialize profit state
  const location = useLocation();
  const iniQuoteData = location.state.quoteData;
  console.log('iniQuoteData',iniQuoteData);
  const iniSingleQuote = iniQuoteData.singleQuote
  const iniQuoteRef = iniSingleQuote.quoteRef
  const iniCustomerName = iniQuoteData.singleQuote.customerId.customerName
  const iniOrderType = iniSingleQuote.orderType || 'Select from the below options'
  console.log('iniCustomerName',iniCustomerName);
  const { quotenumber } = useParams();
  const [formData, setFormData] = useState({
    quoteRef: iniQuoteRef,
    customerName: iniSingleQuote.customerId.customerName,
    customerAddress1: iniSingleQuote.customerId.customerAddress1,
    customerAddress2: iniSingleQuote.customerId.customerAddress2,
    customerAddress3: iniSingleQuote.customerId.customerAddress3,
    customerCity: iniSingleQuote.customerId.customerCity,
    customerCountry: iniSingleQuote.customerId.customerCountry,
    customerPostcode: iniSingleQuote.customerId.customerPostcode,
    orderType: iniOrderType,
    description: iniSingleQuote.description,
    collectionPoint: iniSingleQuote.collectionPoint,
    deliveryPoint: iniSingleQuote.deliveryPoint,
    sellingPrice: iniSingleQuote.sellingPrice,
    sellingPriceCurrency: iniSingleQuote.sellingPriceCurrency,
    quoteROE: iniSingleQuote.quoteROE,
    sellingPriceGBP: iniSingleQuote.sellingPriceGBP,
    buyingPrice: iniSingleQuote.buyingPrice,
    userName: iniSingleQuote.userId.userName,
    supplierQuotes: iniSingleQuote.supplierQuotes.map((quote) => ({
      supplierName: quote.supplierName,
      supplierDescription: quote.supplierDescription,
      supplierQuotePrice: quote.supplierQuotePrice,
      supplierQuoteCurrency: quote.supplierQuoteCurrency,
      supplierQuoteROE: quote.supplierQuoteROE,
      supplierQuoteGBP: quote.supplierQuoteGBP,
      isChecked: false, // Add a new field to store checkbox state
    })),
  })

  const orderTypeOptions = [
    'Select from the below options',
    'Roadfreight Full load - Import',
    'Roadfreight Groupage - Import',
    'Roadfreight Abnormal load - Import',
    'Roadfreight Full load - Export',
    'Roadfreight Groupage - Export',
    'Roadfreight Abnormal load - Export',
    'Roadfreight Full load - Domestic',
    'Roadfreight Groupage - Domestic',
    'Roadfreight Abnormal load - Domestic',
    'Seafreight FCL - Import',
    'Seafreight LCL - Import',
    'Seafreight FCL - Export',
    'Seafreight LCL - Export',
    'Seafreight FCL - Cross Trade',
    'Seafreight LCL - Cross Trade',
    'Seafreight Out-of-gauge - Import',
    'Seafreight Out-of-gauge - Export',
    'Seafreight Out-of-gauge - Cross trade',
    'Seafreight - Charter',
    'Airfreight - Import',
    'Airfreight - Export',
    'Courier - Import',
    'Courier - Export',
    'Courier - Domestic',
    'Customs Clearance only - Export entry',
    'Customs Clearance only - Import entry',
    'Storage',
    'Packing',
    'Container Loading',
    'Container unloading',
    'Freight insurance',
    'Crane Hire',
    'Other logistics service'
  ];
  useEffect(() => {
    const fetchQuoteData = async () => {
      try {
        const response = await axios.get(`${URI}/quotes/${quotenumber}`);
        const quoteData = response.data;
        console.log('quoteData in frontend: ', quoteData);
        const singleQuote = quoteData.singleQuote;
        const quoteRef = singleQuote.quoteRef;
        const orderType = singleQuote.orderType || 'Select from the below options';
        const customerName = singleQuote.customerId.customerName;
        const customerAddress1 = singleQuote.customerId.customerAddress1;
        const customerAddress2 = singleQuote.customerId.customerAddress2;
        const customerAddress3 = singleQuote.customerId.customerAddress3;
        const customerCity = singleQuote.customerId.customerCity;
        const customerPostcode = singleQuote.customerId.customerPostcode;
        const customerCountry = singleQuote.customerId.customerCountry;
        const description = singleQuote.description;
        const collectionPoint = singleQuote.collectionPoint;
        const deliveryPoint = singleQuote.deliveryPoint;
        const sellingPrice = singleQuote.sellingPrice;
        const sellingPriceCurrency = singleQuote.sellingPriceCurrency;
        const quoteROE = singleQuote.quoteROE;
        const sellingPriceGBP = singleQuote.sellingPriceGBP;
        const buyingPrice = singleQuote.buyingPrice;
        const profit = singleQuote.profit;
        const userName = singleQuote.userId.userName;
        
        const supplierQuotes = (singleQuote.supplierQuotes || []).map((quote) => ({
          supplierName: quote.supplierName,
          supplierDescription: quote.supplierDescription,
          supplierQuotePrice: quote.supplierQuotePrice,
          supplierQuoteCurrency: quote.supplierQuoteCurrency,
          supplierQuoteROE: quote.supplierQuoteROE,
          supplierQuoteGBP: quote.supplierQuoteGBP,
          isChecked: false,
        }));
        
        const initialFormData = {
          quoteRef: quoteRef,
          customerName: customerName,
          customerAddress1: customerAddress1,
          customerAddress2: customerAddress2,
          customerAddress3: customerAddress3,
          customerCity: customerCity,
          customerPostcode: customerPostcode,
          customerCountry: customerCountry,
          orderType: orderType,
          description: description,
          collectionPoint: collectionPoint,
          deliveryPoint: deliveryPoint,
          sellingPrice: sellingPrice,
          sellingPriceCurrency: sellingPriceCurrency,
          quoteROE: quoteROE,
          sellingPriceGBP: sellingPriceGBP,
          buyingPrice: buyingPrice,
          profit: profit,
          userName: userName,
          supplierQuotes: supplierQuotes,
        };
        console.log(initialFormData);
        setFormData(initialFormData);
        console.log(formData);
      } catch (error) {
        console.error('Error fetching quote details:', error);
      }
    };
  
    fetchQuoteData();
  }, [quotenumber]);

  const handleAddSupplierQuote = () => {
    setFormData((prevData) => {
      const newSupplier = {
        supplierName: '',
        supplierDescription: prevData.description, // Set the initial supplierDescription to match the description
        initialSupplierDescription: prevData.description, // Store the initial description
        supplierQuotePrice: 0,
        supplierQuoteCurrency: 'gbp',
        supplierQuoteROE: 1,
        supplierQuoteGBP: 0,
        isChecked: false,
      };
      newSupplier.initialSupplierDescription = prevData.description; // Set the initial description
      return {
        ...prevData,
        supplierQuotes: [...prevData.supplierQuotes, newSupplier],
      };
    });
  }
  const handleChange = (name, value, index = 0) => {
    console.log('changing name: ', name);
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      console.log(updatedData);
      const sellingPriceGBP = parseFloat((updatedData.sellingPrice / updatedData.quoteROE).toFixed(2));
      if (name.startsWith('supplierQuotes[')) {
        console.log('name starts with supplierQuotes');
        // Handle changes in supplierQuote fields
        const supplierQuoteIndex = parseInt(name.match(/\[(\d+)\]/)[1]);
        console.log(supplierQuoteIndex);
        console.log('updatedData.supplierQuotes.length', updatedData.supplierQuotes.length);
        if (supplierQuoteIndex >= 0 && supplierQuoteIndex < updatedData.supplierQuotes.length) {
          console.log('the if statement works');
          let supplierQuotes = { ...updatedData.supplierQuotes[supplierQuoteIndex] };
  
          if (name.endsWith('supplierQuotePrice') || name.endsWith('supplierQuoteROE')) {
            console.log('if suppliers price or suppliers roe');
            // Calculate supplierQuotePriceGBP when supplierQuotePrice or supplierQuoteROE changes
            const nameParts = name.split('.');
            const propertyName = nameParts.pop(); // Get the final property name

            supplierQuotes[propertyName] = value;
            const supplierQuotePrice = parseFloat(supplierQuotes.supplierQuotePrice);
            const supplierQuoteROE = parseFloat(supplierQuotes.supplierQuoteROE);
            supplierQuotes.supplierQuoteGBP = parseFloat((supplierQuotePrice / supplierQuoteROE).toFixed(2));

          } else {
            console.log('else not if suppliers price or suppliers roe');
            console.log('supplierQuotes', supplierQuotes);
            console.log('supplierQuotes.supplierName');
            console.log('supplierQuotes[name]',supplierQuotes[name]);
            // Update other supplierQuote fields
            console.log('value', value);
            console.log('name', name);
            const nameParts = name.split('.');
            const propertyName = nameParts.pop(); // Get the final property name

            supplierQuotes[propertyName] = value;
          }
  
          updatedData.supplierQuotes[supplierQuoteIndex] = supplierQuotes;
        }
      } else if (name === 'description') {
        // Handle changes to the description field
        updatedData[name] = value;
        
        // Update the supplierDescriptions for all supplierQuotes
        updatedData.supplierQuotes = updatedData.supplierQuotes.map((quote) => ({
          ...quote,
          supplierDescription: value, // Set supplierDescription to match the description
        }))
      } else {
        // Handle changes in other fields
        updatedData[name] = value;
      }
      updatedData.sellingPriceGBP = sellingPriceGBP
      return updatedData;
    });
  };
  const handleSupplierDescriptionChange = (value, index) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      if (index >= 0 && index < updatedData.supplierQuotes.length) {
        updatedData.supplierQuotes[index].supplierDescription = value;
      }
      return updatedData;
    });
  };
  const handleNewNameChange = (newValue, fieldName) => {
    console.log(newValue);
    console.log(fieldName);
    // Disable further callbacks until enabled again
    setFormData((prevData) => {
      if (Array.isArray(fieldName)) {
        // Updating a nested field within supplierQuote array
        const [arrayName, index, nestedField] = fieldName;
        console.log('prevData', prevData)
        if (
          prevData[arrayName] &&
          Array.isArray(prevData[arrayName]) &&
          prevData[arrayName][index]
          &&
          prevData[arrayName][index][nestedField] !== newValue
        ) { 
          const updatedSupplierQuote = [...prevData[arrayName]];
          console.log('updatedSupplierQuote', updatedSupplierQuote);
          const selectedSupplierName = newValue; // Assuming the name property holds the selected name
          console.log('selectedSupplierName',selectedSupplierName);
          updatedSupplierQuote[index] = {
            ...updatedSupplierQuote[index],
            [nestedField]: selectedSupplierName,
          };
          console.log('updatedSupplierQuote[index]',updatedSupplierQuote[index]);

          return {
            ...prevData,
            [arrayName]: updatedSupplierQuote,
          };
        } else {
          console.log('error')
          console.log(prevData[arrayName]);
          console.log(Array.isArray(prevData[arrayName]));
          console.log(prevData[arrayName][index]);
          console.log(prevData[arrayName][index][nestedField]);
        }
      } else {
        // Updating a single property
        console.log('single property');
        if (prevData[fieldName] !== newValue) {
          return {
            ...prevData,
            [fieldName]: newValue,
          };
        }
      }
      return prevData;
    });
  }
   
  const handleSubmit = async () => {
    console.log('submitted');
    // Perform your POST request here using formData
    await axios.patch(`${URI}/quotes/edit-quote/${quotenumber}`, formData)
      .then((response) => {
        console.log('PATCH request successful:', response.data);
        // Add any success message or further actions here
        window.alert('Enquiry updated!');
        navigate(`/enquiries`);
      })
      .catch((error) => {
        console.error('Error while making the PATCH request:', error);
        // Handle the error or show an error message to the user
      });
  };
  // Handler to toggle the checkbox state
  const handleToggleCheckbox = (index) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      const supplierQuotes = [...updatedData.supplierQuotes];
      supplierQuotes[index].isChecked = !supplierQuotes[index].isChecked;
      updatedData.supplierQuotes = supplierQuotes;
  
      // Calculate the buyingPrice and update the profit
      const buyingPrice = calculateTotalValue(updatedData);
      updatedData.buyingPrice = buyingPrice;
  
      return updatedData;
    });
  };
  
  const calculateTotalValue = (data) => {
    const buyingPrice = data.supplierQuotes.reduce((total, quote) => {
      if (quote.isChecked) {
        return total + parseFloat(quote.supplierQuoteGBP);
      }
      return total;
    }, 0);
  
    const calculatedProfit = (parseFloat(data.sellingPrice) || 0) - buyingPrice;
  
    data.profit = calculatedProfit; // Update the profit in data
    return buyingPrice;
  };

  const [isPopupOpen, setPopupOpen] = useState(false);
  const componentRef = useRef();

  const handleOpenPopup = () => {
    console.log('formData:', formData); // Add this line to log formData
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    console.log('formData:', formData); // Add this line to log formData
    setPopupOpen(false);
  };

  const handlePrintPDF = () => {
    const quoteRef = formData.quoteRef; // Replace this with your quoteRef variable
  
    // Get the content to convert to PDF (replace 'componentRef' with your reference)
    const element = componentRef.current;
  
    // Define PDF document settings
    const pdf = new jsPDF('p', 'mm', 'a4');
    const options = {
      background: 'white',
      scale: 3 // Adjust scale as needed
    };
  
    // Convert HTML element to canvas and then to PDF
    pdf.html(element, options, () => {
      pdf.save(`${quoteRef}.pdf`);
    });
  };
  
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="80%"
      mx="auto"
      bgcolor={ivory}
      p={4}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form>
          <div className='first-section'>
        <AutoCompleteEnquiries
            className="my-input"
            apiUrl={`${URI}/customers/autocomplete?q=`}
              // id="customer"
              value={formData.customerName}
            name="customerName"
            label="Customer"
            onNewNameChange={(newValue) => handleNewNameChange(newValue, 'customerName')}
            />
            <div className="autocomplete-container">
        <Select
          className="my-input"
          name="orderType"
          label="Order Type"
          value={formData.orderType}
          onChange={(event) => handleChange('orderType', event.target.value)}
          sx={{ width: '100%', backgroundColor: 'white' }}
          margin="dense"
        >
          {orderTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          </Select>
          </div>
          <TextField
            name="description"
            label="Description"
            value={formData.description}
            onChange={(event) => handleChange('description', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <TextField
            name="collectionPoint"
            label="Collection Point"
            value={formData.collectionPoint}
            onChange={(event) => handleChange('collectionPoint', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
          <TextField
            name="deliveryPoint"
            label="Delivery Point"
            value={formData.deliveryPoint}
            onChange={(event) => handleChange('deliveryPoint', event.target.value)}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
              />
      <NumericFormat
        className="my-input"
        customInput={TextField}
        thousandSeparator
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        name="sellingPrice"
        label="Sale cost"
        value={formData.sellingPrice}
        onValueChange={({ floatValue }) => handleChange('sellingPrice', floatValue)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
            />

          <div className="autocomplete-container">
      <Select
        className="my-input"
        name="sellingPriceCurrency"
        label="Currency"
        value={formData.sellingPriceCurrency}
        onChange={(event) => handleChange('sellingPriceCurrency', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
      >
        <MenuItem value="gbp">GBP</MenuItem>
        <MenuItem value="usd">USD</MenuItem>
        <MenuItem value="eur">EUR</MenuItem>
            </Select>
            </div>
      <TextField
        type="number"
        name="quoteROE"
        label="Rate of Exchange"
        value={formData.quoteROE}
        onChange={(event) => handleChange('quoteROE', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
            />
            <TextField
              type="number"
              name="sellingPriceGBP"
              label="Selling Price in GBP"
              value={formData.sellingPriceGBP}
              disabled
              fullWidth
              margin="dense"
                  />
      <TextField
        name="userName"
        label="User name"
        value={formData.userName}
        onChange={(event) => handleChange('userName', event.target.value)}
        sx={{ width: '100%', backgroundColor: 'white' }}
        margin="dense"
            />
          </div>
{/*supplier section supplier section supplier section supplier section supplier section supplier section supplier section supplier section supplier section supplier section */}
       {formData.supplierQuotes.map((quote, index) => (
         <div className="supplier-section" key={index}>
           <h2>Supplier Quote {index + 1}</h2> {/* Display the number for each section */}
           <div>
                <input
                  type="checkbox"
                  checked={quote.isChecked}
                  onChange={() => handleToggleCheckbox(index)}
                />
                <label>Add to total buying price?</label>
              </div>
           <MemoizedAutoCompleteEnquiries
            className="my-input"
              apiUrl={`${URI}/suppliers/autocomplete?q=`}
            id="supplier"
            name={`supplierName-${index}`} // Use a unique identifier for each supplier's name
            label="Supplier Name"
            value={quote.supplierName}
            onNewNameChange={(newValue) => handleNewNameChange(newValue, ['supplierQuotes', index, 'supplierName'])}
          />
            <TextField
              name={`supplierQuotes[${index}].supplierDescription`}
              label="Supplier Cargo Details (Description of goods)"
              value={quote.supplierDescription}
              onChange={(event) => handleSupplierDescriptionChange(event.target.value, index)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
             <TextField
              type="number"
              name={`supplierQuotes[${index}].supplierQuotePrice`}
              label="Supplier Price (in suppliers currency)"
              value={quote.supplierQuotePrice}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <Select
              className="my-input"
              name="supplierQuoteCurrency"
              label="Supplier Currency"
              value={quote.supplierQuoteCurrency}
              onChange={(event) => handleChange('supplierQuoteCurrency', event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            >
              <MenuItem value="gbp">GBP</MenuItem>
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="eur">EUR</MenuItem>
            </Select>
            <TextField
              type="number"
              name={`supplierQuotes[${index}].supplierQuoteROE`}
              label="Rate of Exchange"
              value={quote.supplierQuoteROE}
              onChange={(event) => handleChange(event.target.name, event.target.value)}
              sx={{ width: '100%', backgroundColor: 'white' }}
              margin="dense"
            />
            <TextField
              name={`supplierQuotes[${index}].supplierQuoteGBP`}
              label="Supplier Price in GBP"
              value={quote.supplierQuoteGBP}
              disabled
              fullWidth
              margin="dense"
            />
          </div>
       ))}
          <InputLabel htmlFor="buyingPrice">Total Buying Price</InputLabel>
        <TextField
            value={formData.buyingPrice}
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: '100%', backgroundColor: 'white' }}

            margin="dense"
          />
          <InputLabel htmlFor="profit">Profit</InputLabel>
          <TextField
            value={formData.profit}
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: '100%', backgroundColor: 'white' }}
            margin="dense"
          />
      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleAddSupplierQuote}>
          Add Supplier Quote
        </Button>
      </Box>

      <Box my={1}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
        {/* Print PDF button */}
        <Box my={1}>
          <Button variant="contained" color="primary" onClick={handleOpenPopup}>
            Print PDF
          </Button>
        </Box>

        </form>
        {/* Popup for printing PDF */}
        <Dialog open={isPopupOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
          <DialogTitle sx={{ backgroundColor: sage }}>Quotation</DialogTitle>
          <DialogContent sx={{ backgroundColor: sage }}>
            <div ref={componentRef}>
              {/* Pass your quotation data to QuotationContent component */}
              <QuotationContent data={formData} />
            </div>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: sage }}>
            <Button onClick={handleClosePopup}>Back</Button>
            <Button onClick={handlePrintPDF}>Print PDF</Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </Box>
    


)
};

  
export default QuoteForm;
