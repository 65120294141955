import React, { useState, useEffect } from "react";
//import { useHistory } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, Typography, InputLabel } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AddressFormPopup from "../components/AddressFormPopup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import AxiosRateLimit from "axios-rate-limit";

import AutoCompleteInvoice from "../components/AutoCompleteInvoice";
const URI = process.env.REACT_APP_URI;
const currentVAT = process.env.REACT_APP_VAT;

const instance = AxiosRateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 1000,
});

// Wrap the AutoCompleteBookings component with React.memo
const MemoizedAutoCompleteInvoice = React.memo(AutoCompleteInvoice);

const AddInvoice = () => {
  const today = new Date();
  const month = ("0" + (today.getMonth() + 1)).slice(-2); // Get month as two digits ('06' format)
  const year = today.getFullYear().toString().slice(-2); // Get year as two digits ('23' format)

  const [formData, setFormData] = useState({
    quoteRef: "",
    jplRef: "",
    immediatePayment: "No",
    vatAndDuty: "No",
    invoiceDate: today,
    invoiceDueDate: null,
    invoiceTerms: 30,
    orderType: "Select from the below options",
    purchaseOrder: "",
    customerName: "",
    description: "",
    collectionPoint: "",
    deliveryPoint: "",
    collectionDate: null,
    deliveryDate: null,
    sellingPrice: 0,
    sellingPriceCurrency: "gbp",
    rateOfExchange: 1,
    vATCode: 1,
    sellingPriceNett: 0,
    vATAmount: 0,
    sellingPriceGross: 0,
    invoiceMonth: month,
    invoiceYear: year,
    paid: "NP",
    paidDate: null,
    extraInfo: "",
    userName: "",
    bookingMonth: null,
    bookingYear: null,
  });

  const orderTypeOptions = [
    "Select from the below options",
    "Roadfreight Full load - Import",
    "Roadfreight Groupage - Import",
    "Roadfreight Abnormal load - Import",
    "Roadfreight Full load - Export",
    "Roadfreight Groupage - Export",
    "Roadfreight Abnormal load - Export",
    "Roadfreight Full load - Domestic",
    "Roadfreight Groupage - Domestic",
    "Roadfreight Abnormal load - Domestic",
    "Seafreight FCL - Import",
    "Seafreight LCL - Import",
    "Seafreight FCL - Export",
    "Seafreight LCL - Export",
    "Seafreight FCL - Cross Trade",
    "Seafreight LCL - Cross Trade",
    "Seafreight Out-of-gauge - Import",
    "Seafreight Out-of-gauge - Export",
    "Seafreight Out-of-gauge - Cross trade",
    "Seafreight - Charter",
    "Airfreight - Import",
    "Airfreight - Export",
    "Courier - Import",
    "Courier - Export",
    "Courier - Domestic",
    "Customs Clearance only - Export entry",
    "Customs Clearance only - Import entry",
    "Storage",
    "Packing",
    "Container Loading",
    "Container unloading",
    "Freight insurance",
    "Crane Hire",
    "Other logistics service",
  ];

  const handleJplRefNameChange = async (
    newJplRefValue,
    fieldName,
    handleNewNameChange
  ) => {
    console.log("handleJplRefNameChange");
    console.log("newJplRefValue", newJplRefValue);
    const response = await instance.get(`${URI}/booking/${newJplRefValue}`);
    const bookingData = response.data; // Assuming the response contains address details
    console.log("bookingData", bookingData);
    setFormData((prevData) => {
      if (prevData[fieldName] !== newJplRefValue) {
        const customerName = bookingData.customerName;

              // Update supplierBooking array data
        
        // Triggering handleChange with the customerName value
        handleNewNameChange(customerName, 'customerName');

        return {
          ...prevData,
          [fieldName]: newJplRefValue,
          quoteRef: bookingData.singleBooking.quoteRef,
          orderType: bookingData.singleBooking.orderType,
          description: bookingData.singleBooking.description,
          purchaseOrder: bookingData.singleBooking.customerPurchaseOrderNumber,
          collectionPoint: bookingData.singleBooking.collectionPoint,
          deliveryPoint: bookingData.singleBooking.deliveryPoint,
          collectionDate: bookingData.singleBooking.collectionDate,
          deliveryDate: bookingData.singleBooking.deliveryDate,
          sellingPrice: bookingData.singleBooking.sellingPrice,
          sellingPriceCurrency: bookingData.singleBooking.sellingPriceCurrency,
          rateOfExchange: bookingData.singleBooking.quoteROE,
          sellingPriceNett: bookingData.singleBooking.sellingPriceGBP,
          vATCode: bookingData.singleBooking.salesVATCode,
          bookingMonth: bookingData.singleBooking.bookingMonth,
          bookingYear: bookingData.singleBooking.bookingYear
        };
      }
      return prevData;
    });
  };


  const [startFromScratch, setStartFromScratch] = useState(false);

  const handleStartFromScratch = () => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };

      // Define your original default values
      const originalDefaultValues = {
        quoteRef: "",
        jplRef: "",
        immediatePayment: "No",
        vatAndDuty: "No",
        invoiceDate: today,
        invoiceDueDate: null,
        invoiceTerms: 30,
        orderType: "Select from the below options",
        purchaseOrder: "",
        customerName: "",
        description: "",
        collectionPoint: "",
        deliveryPoint: "",
        collectionDate: null,
        deliveryDate: null,
        sellingPrice: 0,
        sellingPriceCurrency: "gbp",
        rateOfExchange: 1,
        vATCode: 1,
        sellingPriceNett: null,
        vATAmount: null,
        sellingPriceGross: null,
        invoiceMonth: month,
        invoiceYear: year,
        paid: "NP",
        paidDate: null,
        extraInfo: "",
        userName: "",
        bookingMonth: null,
        bookingYear: null,
      };

      // Iterate through each field in the formData object
      for (const key in updatedData) {
        // Check if the field is undefined or null
        if (updatedData[key] === undefined || updatedData[key] === null) {
          // Replace the undefined/null field with the original default value
          updatedData[key] = originalDefaultValues[key];
        }
      }

      return updatedData;
    });

    setStartFromScratch(true);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupClosed, setPopupClosed] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupClosed(true);
    setIsPopupOpen(false);
  };

  const handleChange = (field, value) => {
    console.log('handle change');
    let updatedFormData = { ...formData };
  
    // Update the field with the new value
    updatedFormData[field] = value;
  
    // Perform calculations based on the updated field values
    const {
      sellingPrice,
      vATCode,
      rateOfExchange
    } = updatedFormData;
    console.log(sellingPrice,
      vATCode,
      rateOfExchange);
    console.log('VAT',currentVAT);
    console.log(typeof currentVAT);
    console.log('URI', URI);
    console.log(typeof URI);
    const sellingPriceNett = sellingPrice / rateOfExchange;
    let calculatedVATAmount = 0;
  
    if (vATCode === 1) {
      console.log('Vat code is 1');
      calculatedVATAmount = sellingPriceNett * (currentVAT / 100);
    }
    console.log(calculatedVATAmount);
    const tempSellingPriceGross = sellingPriceNett + calculatedVATAmount;
  
    // Update the relevant fields with calculated values
    updatedFormData = {
      ...updatedFormData,
      sellingPriceNett,
      vATAmount: calculatedVATAmount,
      sellingPriceGross: tempSellingPriceGross
    };
  
    // Update the state with the updated form data
    setFormData(updatedFormData);
  };
  

  // Calculate values whenever form fields change
  // useEffect(() => {
  //   let { sellingPrice, sellingPriceNett, vATCode, rateOfExchange, vATAmount } = formData;

  //   // Calculate sellingPriceNett based on vATCode and sellingPrice
  //   sellingPriceNett = sellingPrice / rateOfExchange;

  //   console.log('sellingPriceNett', sellingPriceNett);

  //   // Calculate vATAmount

  //   if (vATCode === 1) {
  //     const vatPercentage = parseFloat(process.env.REACT_APP_VAT);
  //     vATAmount = sellingPriceNett * (vatPercentage / 100);
  //   }

  //   // Calculate sellingPriceGross
  //   const sellingPriceGross = 600// sellingPriceNett + vATAmount;

  //   // Update the form data state with the calculated values
  //   setFormData({
  //     ...formData,
  //     sellingPriceNett,
  //     vATAmount,
  //     sellingPriceGross,
  //   });
  // }, [formData.sellingPrice, formData.vATAmount, formData.rateOfExchange]);


  const handleNewNameChange = (newValue, fieldName) => {
    console.log("handleNewNameChange");
    // Disable further callbacks until enabled again
    setFormData((prevData) => {
      if (Array.isArray(fieldName)) {
        // Updating a nested field within supplierBooking array
        const [arrayName, index, nestedField] = fieldName;

        if (
          prevData[arrayName] &&
          Array.isArray(prevData[arrayName]) &&
          prevData[arrayName][index] &&
          prevData[arrayName][index][nestedField] !== newValue
        ) {
          const updatedSupplierBooking = [...prevData[arrayName]];
          const selectedSupplierName = newValue; // Assuming the name property holds the selected name
          updatedSupplierBooking[index] = {
            ...updatedSupplierBooking[index],
            [nestedField]: selectedSupplierName,
          };
          return {
            ...prevData,
            [arrayName]: updatedSupplierBooking,
          };
        } else {
          console.log("error");
        }
      } else {
        // Updating a single property
        // console.log('prevData[fieldName]', prevData.fieldName);
        if (prevData.customerName === newValue) {
        } else {
          prevData.customerName = newValue;
          // This bit below:
          return {
            ...prevData,
            customerName: prevData.customerName,
          };
        }
        if (prevData[fieldName] !== newValue) {
          return {
            ...prevData,
            [fieldName]: newValue,
          };
        }
      }
      return prevData;
    });
  };

  useEffect(() => {
    console.log('use effect');
    let { sellingPrice, vATCode, rateOfExchange } = formData;
  
    const calculateValues = () => {
      const sellingPriceNett = sellingPrice / rateOfExchange;
      let calculatedVATAmount = 0;
  
      if (vATCode === 1) {
        const vatPercentage = parseFloat(process.env.REACT_APP_VAT);
        calculatedVATAmount = sellingPriceNett * (vatPercentage / 100);
      }
  
      const sellingPriceGross = sellingPriceNett + calculatedVATAmount;
  
      setFormData((prevData) => ({
        ...prevData,
        sellingPriceNett,
        vATAmount: calculatedVATAmount,
        sellingPriceGross,
      }));
    };
  
    calculateValues();
  }, []); 
  
  
  

  const handleSubmit = () => {
    console.log("submitted");

    // Perform a check against formData for undefined or null fields
    const updatedFormData = { ...formData };
    const initialFormData = {
      /* Your initial form data object here */
    };

    // Loop through formData and update undefined/null fields with default values
    for (const key in updatedFormData) {
      if (updatedFormData[key] === undefined || updatedFormData[key] === null) {
        updatedFormData[key] = initialFormData[key];
      }
    }
    if (
      updatedFormData.orderType === "Select from the below options" ||
      formData.orderType === undefined
    ) {
      window.alert("Select shipment type");
      return;
    }

    console.log("formData before sending: ", updatedFormData);

    // Perform your POST request here using updatedFormData
    instance
      .post(`${URI}/booking/new-booking`, updatedFormData)
      .then((response) => {
        // Handle successful response
        console.log("POST request successful:", response.data);
        // Display success message or redirect to another page
        window.alert("Booking Submitted!");
        window.location.href = "/bookings";
      })
      .catch((error) => {
        // Handle error
        console.error("Error while making the POST request:", error);
        console.log(
          "This is what the post request was supposed to be: ",
          updatedFormData
        );
        // Show error message to the user
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="80%"
      mx="auto"
      bgcolor="rgba(240, 240, 240, 0.8)"
      p={4}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form>
          <div className="first-section">
            <AutoCompleteInvoice
              className="my-input"
              apiUrl={`${URI}/booking/autocomplete?q=`}
              id="jplRef"
              name="jplRef"
              label="JPL Ref"
              onNewNameChange={(newValue) =>
                handleJplRefNameChange(newValue, "jplRef", handleNewNameChange)
              }
            />
            {!startFromScratch && (
              <Box my={1}>
                <Typography variant="body1">
                  Enter jpl ref and click continue
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStartFromScratch}
                >
                  Continue
                </Button>
              </Box>
            )}
          </div>
          {startFromScratch && (
            <>
              <div className="first-section">
                <div className="autocomplete-container">
                  <Select
                    className="my-input"
                    name="orderType"
                    label="Order Type"
                    value={formData.orderType}
                    onChange={(event) =>
                      handleChange("orderType", event.target.value)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  >
                    {orderTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* <AutoCompleteExample /> */}
                <AutoCompleteInvoice
                  className="my-input"
                  apiUrl={`${URI}/customers/autocomplete?q=`}
                  id="customer"
                  name="customerName"
                  formData={formData}
                  label="Customer"
                  onNewNameChange={(newValue) =>
                    handleNewNameChange(newValue, "customerName")
                  }
                />
                <TextField
                  name="customerName"
                  label="Temporary placeholder for customer name. Copy to autocomplete for now until issue is fixed."
                  value={formData.customerName}
                  onChange={(event) =>
                    handleChange("customerName", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                />
                <TextField
                  name="userName"
                  label="User"
                  value={formData.userName}
                  onChange={(event) =>
                    handleChange("userName", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                />
                <div className="autocomplete-container">
                <InputLabel id="Immediate-payment-label">Immediate Payment?</InputLabel>
                  <Select
                    className="my-input"
                    name="immediatePayment"
                    labelId="Immediate-payment-label"
                    value={formData.immediatePayment}
                    onChange={(event) =>
                      handleChange("immediatePayment", event.target.value)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </div>
                <InputLabel id='vat-and-duty-label'>Vat and Duty invoice?</InputLabel>
                <Select
                  className="my-input"
                  name="vatAndDuty"
                  labelId="vat-and-duty-label"
                  value={formData.vatAndDuty}
                  onChange={(event) =>
                    handleChange("vatAndDuty", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
                <div className="autocomplete-container">
                  <DatePicker
                    className="my-input"
                    name="invoiceDate"
                    label="Invoice Date"
                    value={formData.invoiceDate}
                    onChange={(date) => handleChange("invoiceDate", date)}
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                    margin="dense"
                    format="dd/MM/yyyy"
                  />
                </div>

                <div className="autocomplete-container">
                  <DatePicker
                    className="my-input"
                    name="invoiceDueDate"
                    label="Invoice Due Date"
                    value={formData.invoiceDueDate}
                    onChange={(date) => handleChange("invoiceDueDate", date)}
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                    margin="dense"
                    format="dd/MM/yyyy"
                    disabled
                  />
                </div>
                <div className="autocomplete-container">
                  <NumericFormat
                    className="my-input"
                    customInput={TextField}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    name="invoiceTerms"
                    label="Invoice terms"
                    value={formData.invoiceTerms}
                    onValueChange={({ floatValue }) =>
                      handleChange("invoiceTerms", floatValue)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  />
                </div>
                <TextField
                  name="customerPurchaseOrderNumber"
                  label="Customer PO"
                  value={formData.purchaseOrder}
                  onChange={(event) =>
                    handleChange("purchaseOrder", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                />



                <TextField
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={(event) =>
                    handleChange("description", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                />
                <TextField
                  name="collectionPoint"
                  label="Collection Point"
                  value={formData.collectionPoint}
                  onChange={(event) =>
                    handleChange("collectionPoint", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                />
                <TextField
                  name="deliveryPoint"
                  label="Delivery Point"
                  value={formData.deliveryPoint}
                  onChange={(event) =>
                    handleChange("deliveryPoint", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                />
                <div className="autocomplete-container">
                  <DatePicker
                    className="my-input"
                    name="collectionDate"
                    label="Collection Date"
                    value={formData.collectionDate}
                    onChange={(date) => handleChange("collectionDate", date)}
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                    margin="dense"
                    format="dd/MM/yyyy"
                  />
                </div>
                <div className="autocomplete-container">
                  <DatePicker
                    className="my-input"
                    name="deliveryDate"
                    label="Delivery Date"
                    value={formData.deliveryDate}
                    onChange={(date) => handleChange("deliveryDate", date)}
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                    margin="dense"
                    format="dd/MM/yyyy"
                  />
                </div>
                <div className="autocomplete-container">
                  <NumericFormat
                    className="my-input"
                    customInput={TextField}
                    thousandSeparator
                    prefix="£"
                    decimalScale={2}
                    fixedDecimalScale
                    name="sellingPrice"
                    label="Sale cost"
                    value={formData.sellingPrice}
                    onValueChange={({ floatValue }) =>
                      handleChange("sellingPrice", floatValue)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  />
                </div>
                <div className="autocomplete-container">
                  <Select
                    className="my-input"
                    name="sellingPriceCurrency"
                    label="Currency"
                    value={formData.sellingPriceCurrency}
                    onChange={(event) =>
                      handleChange("sellingPriceCurrency", event.target.value)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  >
                    <MenuItem value="gbp">GBP</MenuItem>
                    <MenuItem value="usd">USD</MenuItem>
                    <MenuItem value="eur">EUR</MenuItem>
                  </Select>
                </div>
                <TextField
                  type="number"
                  name="rateOfExchange"
                  label="Rate of Exchange"
                  value={formData.rateOfExchange}
                  onChange={(event) =>
                    handleChange("rateOfExchange", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                />
                
                <TextField
                  type="number"
                  name="vATCode"
                  label="VAT code"
                  value={formData.vATCode}
                  onChange={(event) =>
                    handleChange("vATCode", event.target.value)
                  }
                  sx={{ width: "100%", backgroundColor: "white" }}
                  margin="dense"
                />
                <div className="autocomplete-container">
                  <NumericFormat
                    className="my-input"
                    customInput={TextField}
                    thousandSeparator
                    prefix="£"
                    decimalScale={2}
                    fixedDecimalScale
                    name="sellingPriceNett"
                    label="Nett cost"
                    value={formData.sellingPriceNett}
                    onValueChange={({ floatValue }) =>
                      handleChange("sellingPriceNett", floatValue)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  />
                </div>
                
                <div className="autocomplete-container">
                  <NumericFormat
                    className="my-input"
                    customInput={TextField}
                    thousandSeparator
                    prefix="£"
                    decimalScale={2}
                    fixedDecimalScale
                    name="vATAmount"
                    label="VAT"
                    value={formData.vATAmount}
                    onValueChange={({ floatValue }) =>
                      handleChange("vATAmount", floatValue)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  />
                </div>
                <div className="autocomplete-container">
                  <NumericFormat
                    className="my-input"
                    customInput={TextField}
                    thousandSeparator
                    prefix="£"
                    decimalScale={2}
                    fixedDecimalScale
                    name="sellingPriceGross"
                    label="Gross cost"
                    value={formData.sellingPriceGross}
                    onValueChange={({ floatValue }) =>
                      handleChange("sellingPriceGross", floatValue)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  />
                </div>
                <div className="autocomplete-container">
                  <NumericFormat
                    className="my-input"
                    customInput={TextField}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    name="invoiceMonth"
                    label="Invoice Month"
                    value={formData.invoiceMonth}
                    onValueChange={({ floatValue }) =>
                      handleChange("invoiceMonth", floatValue)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  />
                </div>
                <div className="autocomplete-container">
                  <NumericFormat
                    className="my-input"
                    customInput={TextField}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    name="invoiceYear"
                    label="Invoice Year"
                    value={formData.invoiceYear}
                    onValueChange={({ floatValue }) =>
                      handleChange("invoiceYear", floatValue)
                    }
                    sx={{ width: "100%", backgroundColor: "white" }}
                    margin="dense"
                  />
                </div>
              </div>

              <Box my={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </>
          )}
        </form>
      </LocalizationProvider>
    </Box>
  );
};

export default AddInvoice;
