import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import AxiosRateLimit from 'axios-rate-limit'
const instance = AxiosRateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 2000
})

export default function AutoCompleteTracking({
  apiUrl,
  id,
  name,
  label,
  value,
  onNewNameChange,
  formData
}) {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(value);
  const [inputValue, setInputValue] = useState(''); // Set inputValue initially to the provided value
  const [initialRender, setInitialRender] = useState(true);
  const [stopUpdate, setStopUpdate] = useState(false);

  console.log('value',value);
  // Gets the data from the api url. This should be defined in the parent code.
  useEffect(() => {

    const fetchOptions = async () => {
      try {
        const response = await instance.get(apiUrl);
        setOptions(response.data);
      } catch (error) {
        console.error(error);
        setOptions([]); // Set options to an empty array on error
      }
    };

    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      if (!stopUpdate && value !== selectedValue) {
        setSelectedValue(value);
        setInputValue(value);
      } else if (name === 'customerName') {
        setSelectedValue(formData.customerName);
        setStopUpdate(true);
      } else {
        setStopUpdate(true);
      }
    }
  }, [value, selectedValue, stopUpdate, initialRender, formData, name]);
  const handleChange = (event, newValue) => {
  console.log(newValue, newValue);
    setSelectedValue(newValue); // Update the selectedValue state
    onNewNameChange(newValue); // Notify the parent component with the new value
  };
  // useEffect(() => {
  //   if (!initialRender && selectedValue !== value) {
  //     console.log('not initial render and selectedValue is not value');
  //     onNewNameChange(selectedValue);
  //   }
  // }, [selectedValue, onNewNameChange, value, initialRender]);
  useEffect(() => {

    if (value !== selectedValue) {
      value = selectedValue
      setInputValue(selectedValue)
      onNewNameChange(value);
    } else {
      if (selectedValue !== '' || value !== '') {
        console.log('value and selected value are the same');
      }
    }
  }, [value, selectedValue]
)

  return (
    <Autocomplete
      className="my-input"
      disablePortal
      id={id} // Use the id prop
      name={name} // Use the name prop
      options={options}
      inputValue={inputValue} // Set inputValue to control the input value
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue); // Update inputValue when input changes
      }}
      value={selectedValue}
      sx={{ width: '100%', backgroundColor: 'white'  }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label} // Use the label prop
        />
      )}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === Number(value.id)}
    />
  );
  
}
