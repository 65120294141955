// Bookings.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS
const URI = process.env.REACT_APP_URI

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 250 },
  {
    field: 'orderType',
    headerName: 'Order Type',
    width: 300,
    editable: true,
  },
  {
    field: 'clause',
    headerName: 'Quotation Clause',
    width: 800,
    editable: true,
  }
];

function QuotationClauses() {
  const [quotationClauses, setQuotationClauses] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState({});

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        console.log('Get request sent for quotationClauses');
        const response = await axios.get(`${URI}/quotation-clauses/get-quotation-clauses`)
        console.log(response);
        setQuotationClauses(response.data.quotationClauses)
        setLoading(false)
      } catch (error) {
        setError('Error fetching quotation clauses'); // Set the error message
        setLoading(false); // Set loading to false even on error
     }
    }
    fetchQuotes()
  }, []);

  const navigate = useNavigate();

  const handleRowClick = async (params) => {
    const id = params.row.id;
    
    try {
      const response = await axios.get(`${URI}/quotation-clauses/${id}`);
      const quoteData = response.data; // Assuming the response contains booking details
      console.log('quoteData', quoteData);
      // You can navigate to the details page and pass the booking data as a route parameter
      navigate(`/quotation-clauses/${id}`, { state: { quoteData } });
    } catch (error) {
      console.error('Error fetching booking data', error);
    }
  };
  const handleCheckboxChange = (params) => {
    const rowId = params.id;
    setSelectedRowIds((prevSelectedRowIds) => ({
      ...prevSelectedRowIds,
      [rowId]: !prevSelectedRowIds[rowId], // Toggle selection status
    }));
  };
  const deleteSelectedQuotationClauses = async () => {
    const selectedQuoteRefs = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);
  
    if (selectedQuoteRefs.length === 0) {
      return; // No bookings selected, do nothing
    }
  
    confirmAlert({
      title: 'Confirm Deletion',
      message: `Are you sure you want to delete quotation clauses ${selectedQuoteRefs.join(', ')}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              for (const id of selectedQuoteRefs) {
                await axios.delete(`${URI}/quotation-clauses/delete-quotation-clause/${id}`);
              }
              // Update the UI or state as needed after successful deletion
              // For example, you can refresh the page here
              window.location.reload();
            } catch (error) {
              console.error('Error deleting quotation clauses:', error);
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
            // Do nothing, user canceled
          },
        },
      ],
    });
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const rows = quotationClauses.map(clause => ({
    id: clause._id,  // Use _id as the id property
    orderType: clause.orderType,
    clause: clause.clause,
  }));
 
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onSelectionModelChange={(newSelection) => {
          // No need to set selectedRowIds here
        }}
        selectionModel={Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId])}
        onRowClick={handleRowClick}
        onCellClick={(params) => {
          if (params.field === '__check__') {
            handleCheckboxChange(params.row);
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
      <Link to="/add-quotation-clause"> {/* Add the Link component wrapping the button */}
        <button>Create New Quotation Clause</button>
      </Link>
        <button onClick={deleteSelectedQuotationClauses}>Delete Quotation Clause</button>
    </Box>
  );
}

export default QuotationClauses
