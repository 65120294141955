// InvoiceContent.js
import React, {useEffect, useState} from 'react';
import { Box, Paper, Typography, Divider } from '@mui/material';
import { darkBlue } from '../constants/colors'; // Import the darkBlue color
import LogoImage from '../images/fifteen-step-logo.png'
import axios from 'axios'
const URI = process.env.REACT_APP_URI

const QuotationContent = ({ data }) => {
  const [quotationClauses, setQuotationClauses] = useState([]);

  useEffect(() => {
    // Make the API call to get quotation clauses
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URI}/quotation-clauses/get-quotation-clauses`);
        setQuotationClauses(response.data.quotationClauses);
      } catch (error) {
        console.error('Error fetching quotation clauses:', error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [data.id]);
  // Ensure that 'data' is defined before attempting to access its properties
  if (!data) {
    return <div>No data available</div>;
  }

  const {
    quoteRef,
    customerName,
    collectionPoint,
    deliveryPoint,
    orderType,
    description,
    customerAddress1,
    customerAddress2,
    customerAddress3,
    customerCity,
    customerPostcode,
    customerCountry,
    sellingPrice,
    sellingPriceCurrency,
    quoteROE,
    sellingPriceGBP,
  } = data;
  console.log('data for qutoation content', data);
  return (
    <Box p={2}>
    {/* Buttons outside of the Paper component */}
    <Box mb={2}>
      {/* Add any additional buttons or actions outside of the Paper component */}
    </Box>

    {/* A4-sized Paper component */}
    <Paper
      style={{
        width: '210mm', // A4 width
        height: '297mm', // A4 height
        padding: '20px', // Add padding as needed
        display: 'flex',
        flexDirection: 'column',
      }}
    >
       {/* Main Content */}
       <Box flexGrow={1} display="flex" flexDirection="column">
      {/* Logo and Quotation Ref */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Quotation Ref: {quoteRef}</Typography>
        <Box>

          {/* Add logo image here */}
          <img src={LogoImage} alt="Company Logo" style={{ maxWidth: 100 }} />
        </Box>
      </Box>

      {/* Line break */}
      <Divider sx={{ backgroundColor: darkBlue, height: 2, margin: '10px 0' }} />

      {/* From and To addresses */}
      <Box display="flex">
        {/* From Address */}
        <Box width="50%" border="1px solid black" p={2}>
          <Typography variant="subtitle1">From</Typography>
          <Typography variant="body2">
            7 River Court, Brighouse Road,
            <br />
            Brighouse Business Village,
            <br />
            Middlesbrough, TS2 1RT
          </Typography>
        </Box>

        {/* To Address */}
        <Box width="50%" border="1px solid black" p={2}>
          <Typography variant="subtitle1">To</Typography>
              <Typography variant="body2">
            {customerName}
            <br />
            {customerAddress1}
            <br />
            {customerAddress2}
            <br />
            {customerAddress3}
            <br />
            {customerCity}, {customerPostcode}
            <br />
            {customerCountry}
          </Typography>
        </Box>
      </Box>

      {/* Line break */}
      <Divider sx={{ backgroundColor: darkBlue, height: 2, margin: '10px 0' }} />

      {/* Collection and Delivery Points */}
      <Box display="flex">
        <Box width="50%" p={2}>
          <Typography variant="subtitle1">Collection Point</Typography>
          <Typography variant="body2">{collectionPoint}</Typography>
        </Box>
        <Box width="50%" p={2}>
          <Typography variant="subtitle1">Delivery Point</Typography>
          <Typography variant="body2">{deliveryPoint}</Typography>
        </Box>
      </Box>

      {/* Order Type */}
      <Box p={2}>
        <Typography variant="subtitle1">Order Type</Typography>
        <Typography variant="body2">{orderType}</Typography>
      </Box>

      {/* Line break */}
      <Divider sx={{ backgroundColor: darkBlue, height: 2, margin: '10px 0' }} />
      {/* Description */}
      <Box p={2}>
        <Typography variant="subtitle1">Description</Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
          {description}
        </Typography>
      </Box>

      <Box p={2}>
  <Typography variant="subtitle1">Quotation Clauses</Typography>
  {quotationClauses.map((clause) => (
    <Typography key={clause._id} variant="body2">
      {clause.clause}
    </Typography>
  ))}
</Box>

<Box flexGrow={1} display="flex" flexDirection="column">
    {/* Existing content goes here */}

    {/* Selling Price Details Divider at the bottom */}
    <Divider sx={{ backgroundColor: darkBlue, height: 2, margin: '10px 0', mt: 'auto' }} />
  </Box>

 {/* Selling Price Details */}
 <Box display="flex" justifyContent="space-between" mt="auto" p={2}>
  
      <Box>
        <Typography variant="subtitle1">Selling Price</Typography>
        <Typography variant="body2">{sellingPrice}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">Currency</Typography>
        <Typography variant="body2">{sellingPriceCurrency}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">Rate of Exchange</Typography>
        <Typography variant="body2">{quoteROE}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">Selling Price in GBP</Typography>
        <Typography variant="body2">{sellingPriceGBP}</Typography>
      </Box>
    </Box>
  </Box>

{/* Footer */}
<Box p={2}>
{/* Add your footer content here */}
<Typography variant="body2">Company Registration No: XXXXXXX</Typography>
{/* Add more details as needed */}
</Box>
      </Paper>
    </Box>
  );
};

export default QuotationContent;
