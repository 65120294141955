// Trackings.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Box, TextField, Select, MenuItem, Typography, FormControl, InputLabel } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS
const URI = process.env.REACT_APP_URI

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 30 },
  {
    field: 'jplRef',
    headerName: 'Shipment Ref',
    width: 100,
    editable: true,
  },
  {
    field: 'orderType',
    headerName: 'Shipment type',
    width: 200,
    editable: true,
  },
  {
    field: 'customerName',
    headerName: 'Customer name',
    width: 150,
    editable: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
    editable: true,
  },
  {
    field: 'collectionPoint',
    headerName: 'Collecting from',
    width: 120,
    editable: true,
  },
  {
    field: 'collectedDate',
    headerName: 'Collecting on',
    type: 'date',
    width: 120,
    editable: true,
    valueGetter: (params) => {
      return new Date(params.value);
    },
  },
  {
    field: 'collected',
    headerName: 'Collected?',
    width: 80,
    editable: true,
    valueGetter: (params) => {
      return params.value === 1 ? 'Yes' : 'No';
    },
  },
  {
    field: 'deliveryPoint',
    headerName: 'Delivery point',
    width: 120,
    editable: true,
  },
  {
    field: 'deliveredDate',
    headerName: 'Delivering on',
    type: 'date',
    width: 120,
    editable: true,
    valueGetter: (params) => {
      return new Date(params.value);
    },
    
  },
  {
    field: 'delivered',
    headerName: 'Delivered?',
    width: 80,
    editable: true,
    valueGetter: (params) => {
      return params.value === 1 ? 'Yes' : 'No';
    },
  },
  {
    field: 'userName',
    headerName: 'User',
    width: 80,
    editable: true,
  },
];



function Trackings() {
  const [trackings, setTrackings] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState({});

  useEffect(() => {
    const fetchTrackings = async () => {
      try {
        const response = await axios.get(`${URI}/tracking/track-all-shipments`)
        setTrackings(response.data.allTrackings)
        setLoading(false)
      } catch (error) {
        setError('Error fetching trackings'); // Set the error message
        setLoading(false); // Set loading to false even on error
     }
    }
    fetchTrackings()
  }, []);
  
  const navigate = useNavigate();

  const handleRowClick = async (params) => {
    const jplRef = params.row.jplRef;
    console.log('jplRef',jplRef);
    try {
      const response = await axios.get(`${URI}/tracking/${jplRef}`);
      const trackingData = response.data; // Assuming the response contains tracking details
      console.log(trackingData);
      // You can navigate to the details page and pass the tracking data as a route parameter
      navigate(`/tracking-details/${jplRef}`, { state: { trackingData } });
    } catch (error) {
      console.error('Error fetching tracking data', error);
    }
  };

  const handleCheckboxChange = (params) => {
    const rowId = params.jplRef;
    setSelectedRowIds((prevSelectedRowIds) => ({
      ...prevSelectedRowIds,
      [rowId]: !prevSelectedRowIds[rowId], // Toggle selection status
    }));
  };
 // Log selected jplRefs when checkboxes are selected/deselected
 useEffect(() => {
  const selectedJPLRefs = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);
  console.log('Selected JPLRefs:', selectedJPLRefs);
 }, [selectedRowIds]);
  
const deleteSelectedTrackings = async () => {
  const selectedJPLRefs = Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]);

  if (selectedJPLRefs.length === 0) {
    return; // No trackings selected, do nothing
  }

  confirmAlert({
    title: 'Confirm Deletion',
    message: `Are you sure you want to delete trackings ${selectedJPLRefs.join(', ')}?`,
    buttons: [
      {
        label: 'Yes',
        onClick: async () => {
          try {
            for (const jplRef of selectedJPLRefs) {
              await axios.delete(`${URI}/tracking/${jplRef}`);
            }
            // Update the UI or state as needed after successful deletion
            // For example, you can refresh the page here
            window.location.reload();
          } catch (error) {
            console.error('Error deleting trackings:', error);
          }
        },
      },
      {
        label: 'No',
        onClick: () => {
          // Do nothing, user canceled
        },
      },
    ],
  });
};


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  // Convert the trackings array to the format expected by the Datagrid
  const rows = trackings.map((tracking, index) => ({
    id: index + 1,
    jplRef: tracking.jplRef,
    orderType: tracking.booking.orderType,
    customerName: tracking.customerId.customerName,
    description: tracking.booking.description,
    collectionPoint: tracking.booking.collectionPoint,
    collectedDate: tracking.collectedDate,
    collected: tracking.collected,
    deliveryPoint: tracking.booking.deliveryPoint,
    deliveredDate: tracking.deliveredDate,
    delivered: tracking.delivered,
    userName: tracking.userId.userName,
  }));
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onSelectionModelChange={(newSelection) => {
          // No need to set selectedRowIds here
        }}
        selectionModel={Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId])}
        onRowClick={handleRowClick}
        onCellClick={(params) => {
          if (params.field === '__check__') {
            handleCheckboxChange(params.row);
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
      <Typography>
        Add select by date for everything collecting/delivering on certain dates
        <br />
          Add functionality so it displays collection date if collected === 0 or collected date if collected === 1
      </Typography>
      <Link to="/add-tracking"> {/* Add the Link component wrapping the button */}
        <button>Create New Tracking</button>
      </Link>
        <button onClick={deleteSelectedTrackings}>Delete Tracking</button>
    </Box>
  );
}

export default Trackings;