import { Link } from 'react-router-dom'
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoImage from '../images/fifteen-step-logo.png'

// import { ivory, gray, darkBlue, lightBlue, sage } from '../constants/colors'

const Navbar = () => {

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white'}}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <img src={LogoImage} alt="Your Logo" style={{ width: '100px', height: 'auto' }} />
        </Typography>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        {/* Use the Link component for navigation links */}
        <Typography variant="h6" component="div" sx={{ display: { xs: 'none', md: 'block' } }}>
        <span style={{ marginRight: '10px' }}>
            <Link to="/">Home</Link>
          </span>
          {/* <span style={{ marginRight: '10px' }}>
            <Link to="/about">About</Link>
          </span> */}
          {/* <span style={{ marginRight: '10px' }}>
            <Link to="/contact">Contact</Link>
          </span> */}
          <span style={{ marginRight: '10px' }}>
            <Link to="/addresses">Addresses</Link>
          </span>
          <span style={{ marginRight: '10px'}}>
            <Link to="/enquiries">Enquiries</Link>
          </span>
          <span style={{ marginRight: '10px'}}>
          <Link to="/bookings">Bookings</Link>
          </span>
          <span style={{ marginRight: '10px'}}>
          <Link to="/quotation-clauses">Quotation Clauses</Link>
          </span>
          <span style={{ marginRight: '10px'}}>
          <Link to="/tracking">Tracking</Link>
          </span>
          <span style={{ marginRight: '10px'}}>
          <Link to="/invoices">Invoices</Link>
          </span>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
 
export default Navbar;